import { ISetting, ISlicePiece } from "@one/core";
import { TenantSubscriptionStatus } from "../../models/enums/TenantSubscriptionStatus";
import { OneThemes } from "../../styles/OneThemeProvider";

export enum ApplicationContextTypes {
	FETCH_SETTINGS_STARTED = "FETCH_SETTINGS_STARTED",
	FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS",
	FETCH_SETTINGS_FAILURE = "FETCH_SETTINGS_FAILURE",

	CHECK_TENANT_SUBSCRIPTION_STARTED = "CHECK_TENANT_SUBSCRIPTION_STARTED",
	CHECK_TENANT_SUBSCRIPTION_SUCCESS = "CHECK_TENANT_SUBSCRIPTION_SUCCESS",
	CHECK_TENANT_SUBSCRIPTION_FAILED = "CHECK_TENANT_SUBSCRIPTION_FAILED",

	UPDATE_APPLICATION_SETTINGS_STARTED = "UPDATE_APPLICATION_SETTINGS_STARTED",
	UPDATE_APPLICATION_SETTINGS_SUCCESS = "UPDATE_APPLICATION_SETTINGS_SUCCESS",
	UPDATE_APPLICATION_SETTINGS_FAILED = "UPDATE_APPLICATION_SETTINGS_FAILED",
	RESET_APPLICATION_SETTINGS_UPDATE_STATUS = "RESET_APPLICATION_SETTINGS_UPDATE_STATUS",

	SET_IS_ON_MOBILE = "SET_IS_ON_MOBILE",
	
	SET_THEME = "SET_THEME",
	SET_DISPLAY_NAME = "SET_DISPLAY_NAME",
	SET_IMAGE_URL = "SET_IMAGE_URL"
}

export type ApplicationContextState = Readonly<{
	settings: ISlicePiece<{
		applicationSettings: ISetting[];
		tenantSettings: ISetting[];
		userSettings: ISetting[];
	}>;

	tenantSubscription: ISlicePiece<TenantSubscriptionStatus>;
	isOnMobile: boolean;

	theme: OneThemes;
	displayName: string | null;
	imageUrl:  string | null;
}>;
