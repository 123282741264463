import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { AdminActionTypes, AdminState } from "./types";
import { msalGraphFetch, msalPowerBiFetch } from "../../MSALConfig";
import { Group } from "@microsoft/microsoft-graph-types";

export const fetchDashboardsForWorkspaceId: ActionCreator<ThunkAction<Promise<any>, AdminState, null, any>> = (
	groupId: number
) => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: AdminActionTypes.FETCH_DASHBOARDS_FOR_WORKSPACE_STARTED,
		});

		try {
			const response = await msalPowerBiFetch(
				`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/dashboards`,
				{}
			);

			if (response.status !== 200) {
				dispatch({
					type: AdminActionTypes.FETCH_DASHBOARDS_FOR_WORKSPACE_FAILURE,
				});
				return;
			}
			const result = await response.json();

			dispatch({
				type: AdminActionTypes.FETCH_DASHBOARDS_FOR_WORKSPACE_SUCCESS,
				payload: result.value,
			});
		} catch (e) {
			dispatch({
				type: AdminActionTypes.FETCH_DASHBOARDS_FOR_WORKSPACE_FAILURE,
			});
		}
	};
};

export const fetchReportsForWorkspaceId: ActionCreator<ThunkAction<Promise<any>, AdminState, null, any>> = (
	groupId: number
) => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: AdminActionTypes.FETCH_REPORTS_FOR_WORKSPACE_STARTED,
		});

		try {
			const response = await msalPowerBiFetch(`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports`, {});

			if (response.status !== 200) {
				dispatch({
					type: AdminActionTypes.FETCH_REPORTS_FOR_WORKSPACE_FAILURE,
				});
				return;
			}
			const result = await response.json();

			dispatch({
				type: AdminActionTypes.FETCH_REPORTS_FOR_WORKSPACE_SUCCESS,
				payload: result.value,
			});
		} catch (e) {
			dispatch({
				type: AdminActionTypes.FETCH_REPORTS_FOR_WORKSPACE_FAILURE,
			});
		}
	};
};

export const fetchWorkspaces: ActionCreator<ThunkAction<Promise<any>, AdminState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: AdminActionTypes.FETCH_WORKSPACES_STARTED,
		});

		try {
			const response = await msalPowerBiFetch("https://api.powerbi.com/v1.0/myorg/groups", {});

			if (response.status !== 200) {
				dispatch({
					type: AdminActionTypes.FETCH_WORKSPACES_FAILURE,
				});
				return;
			}
			const result = await response.json();

			dispatch({
				type: AdminActionTypes.FETCH_WORKSPACES_SUCCESS,
				payload: result.value,
			});
		} catch (e) {
			dispatch({
				type: AdminActionTypes.FETCH_WORKSPACES_FAILURE,
			});
		}
	};
};

export const fetchSecurityGroups = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchSecurityGroupsRequest());
	};
};

const fetchSecurityGroupsRequest: ActionCreator<ThunkAction<Promise<any>, AdminState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchSecurityGroupsStarted());

		try {
			const fetchGroups = async (nextLink?: string, previousResults?: Group[]) => {
				const urlToUse = nextLink
					? nextLink
					: `https://graph.microsoft.com/v1.0/groups?$top=999&$filter=securityEnabled eq true&$select=id,displayName,securityEnabled`;
				const response: Response = await msalGraphFetch(urlToUse, {});

				if (response) {
					// If the reponse code is not positive, throw an error.
					if (response.status !== 200) {
						return -1;
					}

					let jsonResponse = await response.json();

					// Create a new list or use the previousResults if available. Add the jsonResponse.value to this list.
					let fetchedGroups: Group[] = previousResults ? previousResults : [];
					fetchedGroups = fetchedGroups.concat(jsonResponse.value);

					// If there is a nextLink, execute the function again.
					if (jsonResponse["@odata.nextLink"]) {
						return fetchGroups(jsonResponse["@odata.nextLink"], fetchedGroups);
					} else {
						return fetchedGroups;
					}
				}
			};

			const result = await fetchGroups();
			if (result === -1 || result === undefined) {
				dispatch(fetchSecurityGroupsFailure("error"));
				return;
			} else {
				dispatch(fetchSecurityGroupsSuccess(result));
			}
		} catch (e) {
			dispatch(fetchSecurityGroupsFailure("error"));
		}
	};
};

const fetchSecurityGroupsStarted = () => ({
	type: AdminActionTypes.FETCH_SECURITY_GROUPS_STARTED,
});

const fetchSecurityGroupsSuccess = (securityGroups: any) => ({
	type: AdminActionTypes.FETCH_SECURITY_GROUPS_SUCCESS,
	payload: securityGroups,
});

const fetchSecurityGroupsFailure = (errorMessage: string | object) => ({
	type: AdminActionTypes.FETCH_SECURITY_GROUPS_FAILURE,
	payload: {
		errorMessage,
	},
});
