import {
	IPortalPageCreate,
	IPortalPageUpdate,
	IPortalPageStatusUpdate,
	IPortalPage,
	IPortalPageContent,
} from "@one/core";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { CurrentPageActionToExecute } from "../../models/enums/CurrentPageActionToExecute";
import { msalOneFetch } from "../../MSALConfig";
import { PagesActionTypes, PagesState } from "./types";

export const setActionToExecute = (newValue: CurrentPageActionToExecute) => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: PagesActionTypes.SET_ACTION_TO_EXECUTE,
			payload: newValue,
		});
	};
};
export const setEditMode = (newValue: boolean) => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: PagesActionTypes.SET_EDIT_MODE,
			payload: newValue,
		});
	};
};
export const setCurrentPage = (newCurrentPage: IPortalPage | null) => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: PagesActionTypes.SET_CURRENT_PAGE,
			payload: newCurrentPage,
		});
	};
};

export const fetchPages: ActionCreator<ThunkAction<Promise<any>, PagesState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: PagesActionTypes.FETCH_PAGES_STARTED,
		});

		try {
			const response: Response = await msalOneFetch(
				`${process.env.REACT_APP_ONE_API_URL}​/api/v1.0/portal-pages`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch({
						type: PagesActionTypes.FETCH_PAGES_FAILURE,
					});
					return;
				}

				const result: IPortalPage[] = await response.json();
				dispatch({
					type: PagesActionTypes.FETCH_PAGES_SUCCESS,
					payload: result,
				});
			}
		} catch (e) {
			dispatch({
				type: PagesActionTypes.FETCH_PAGES_FAILURE,
			});
		}
	};
};

/* CREATE */
export const createPortalPage: ActionCreator<ThunkAction<Promise<any>, PagesState, null, any>> = (
	page: IPortalPageCreate
) => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: PagesActionTypes.CREATE_PAGE_STARTED,
		});

		try {
			const response: Response = await msalOneFetch(
				`${process.env.REACT_APP_ONE_API_URL}​/api/v1.0/portal-pages`,
				{
					method: "POST",
					body: JSON.stringify(page),
				}
			);

			if (response) {
				if (response.status !== 200 && response.status !== 204) {
					dispatch({
						type: PagesActionTypes.CREATE_PAGE_FAILURE,
					});
					return;
				}

				const createdPage = await response.json();
				dispatch({
					type: PagesActionTypes.CREATE_PAGE_SUCCESS,
					payload: createdPage
				});
			}
		} catch (e) {
			dispatch({
				type: PagesActionTypes.CREATE_PAGE_FAILURE,
			});
		}
	};
};

/* UPDATE PAGE */
export const updatePortalPage: ActionCreator<ThunkAction<Promise<any>, PagesState, null, any>> = (
	page: IPortalPageUpdate
) => {
	return async (dispatch: Dispatch) => {
		dispatch({ type: PagesActionTypes.UPDATE_PAGE_STARTED });

		try {
			const response: Response = await msalOneFetch(
				`${process.env.REACT_APP_ONE_API_URL}​/api/v1.0/portal-pages/${page.id}`,
				{
					method: "PATCH",
					body: JSON.stringify(page),
				}
			);

			if (response) {
				if (response.status !== 200 && response.status !== 204) {
					dispatch({ type: PagesActionTypes.UPDATE_PAGE_FAILURE });
					return;
				}

				const updatePage = await response.json();
				dispatch({
					type: PagesActionTypes.UPDATE_PAGE_SUCCESS,
					payload: updatePage,
				});
			}
		} catch (e) {
			dispatch({ type: PagesActionTypes.UPDATE_PAGE_FAILURE });
		}
	};
};

/* UPDATE STATUS */
export const updatePortalPageStatus: ActionCreator<ThunkAction<Promise<any>, PagesState, null, any>> = (
	page: IPortalPageStatusUpdate
) => {
	return async (dispatch: Dispatch) => {
		dispatch({ type: PagesActionTypes.UPDATE_PAGE_STATUS_STARTED });

		try {
			const response: Response = await msalOneFetch(
				`${process.env.REACT_APP_ONE_API_URL}​/api/v1.0/portal-pages/${page.id}/update-status`,
				{
					method: "PATCH",
					body: JSON.stringify(page),
				}
			);

			if (response) {
				if (response.status !== 200 && response.status !== 204) {
					dispatch({ type: PagesActionTypes.UPDATE_PAGE_STATUS_FAILURE });
					return;
				}

				const updatedPage = await response.json();
				dispatch({
					type: PagesActionTypes.UPDATE_PAGE_STATUS_SUCCESS,
					payload: updatedPage,
				});
			}
		} catch (e) {
			dispatch({
				type: PagesActionTypes.UPDATE_PAGE_STATUS_FAILURE,
			});
		}
	};
};

/* UPDATE CONTENT */
export const updatePortalPageContent: ActionCreator<ThunkAction<Promise<any>, PagesState, null, any>> = (
	pageId: number,
	newContent: IPortalPageContent
) => {
	return async (dispatch: Dispatch) => {
		dispatch({ type: PagesActionTypes.UPDATE_PAGE_CONTENT_STARTED });
		
		try {
			const response: Response = await msalOneFetch(
				`${process.env.REACT_APP_ONE_API_URL}​/api/v1.0/portal-pages/${pageId}/update-content`,
				{
					method: "PATCH",
					body: JSON.stringify({ content: JSON.stringify(newContent) }),
				}
			);

			if (response) {
				if (response.status !== 200 && response.status !== 204) {
					dispatch({ type: PagesActionTypes.UPDATE_PAGE_CONTENT_FAILURE });
					return;
				}

				const updatedPage = await response.json();
				dispatch({
					type: PagesActionTypes.UPDATE_PAGE_CONTENT_SUCCESS,
					payload: updatedPage,
				});
			}
		} catch (e) {
			dispatch({
				type: PagesActionTypes.UPDATE_PAGE_CONTENT_FAILURE,
			});
		}
	};
};

/* UPDATE ORDER/D&D */
export const updatePortalPageOrder: ActionCreator<ThunkAction<Promise<any>, PagesState, null, any>> = (
	pageId: number,
	parentId: number,
	position: number
) => {
	return async (dispatch: Dispatch) => {
		dispatch({ type: PagesActionTypes.UPDATE_PAGE_ORDER_STARTED });

		try {
			let obj = { 
				parentId: parentId,
				position: position
			};
			
			const response: Response = await msalOneFetch(
				`${process.env.REACT_APP_ONE_API_URL}​/api/v1.0/portal-pages/${pageId}/update-position`,
				{
					method: "PATCH",
					body: JSON.stringify(obj),
				}
			);

			if (response) {
				if (response.status !== 200 && response.status !== 204) {
					dispatch({ type: PagesActionTypes.UPDATE_PAGE_ORDER_FAILURE });
					return;
				}

				const updatedPages = await response.json();
				dispatch({
					type: PagesActionTypes.UPDATE_PAGE_ORDER_SUCCESS,
					payload: {
						pageId,
						updatedPages
					},
				});
			}
		} catch (e) {
			dispatch({
				type: PagesActionTypes.UPDATE_PAGE_ORDER_FAILURE,
			});
		}
	};
};
