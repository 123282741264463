import * as React from "react";
import { OneNavigation } from "@one/web";
import { hasAccess, IOneNavigationPage, IOneNavigationPageMenuAction, IPortalPage, PermissionCodes, SlicePieceStatus } from "@one/core";
import { useEffect, useState } from "react";
import { ActionButton } from "@fluentui/react";
import { UpsertPageModal } from "./UpsertPageModal/UpsertPageModal";
import { fetchSecurityGroups } from "../../../store/admin/actions";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import { fetchPages, updatePortalPageOrder } from "../../../store/pages/actions";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { PageDeleteDialog } from "./pageDeleteDialog/PageDeleteDialog";

export interface INavigationProps {
	mobilePanelIsOpen: boolean;
	setMobilePanelIsOpen: (newValue: boolean) => void;
}

export const Navigation = (_props: INavigationProps) => {
	const dispatch = useDispatch();
	const intl = useIntl();
	const history = useHistory();
	const [selectedItem, setSelectedItem] = useState<IOneNavigationPage | null>(null);
	const [addModalIsOpen, setAddModalIsOpen] = useState<boolean>(false);
	const [pageToUpdate, setPageToUpdate] = useState<IOneNavigationPage | null>(null);
	const [pageToDelete, setPageToDelete] = useState<IOneNavigationPage | null>(null);
	const [pages, setPages] = useState<IOneNavigationPage[]>([]);
	const [addModalParentPage, setAddModalParentPage] = useState<IOneNavigationPage>();
	const { pagesSlice, applicationContextSlice, userSlice } = useSelector((state: IApplicationState) => state);

	useEffect(() => {
		dispatch(fetchSecurityGroups());
		dispatch(fetchPages());
	}, []);

	useEffect(() => {
		if (pagesSlice.currentPage && pagesSlice.currentPage.page) {
			let mappedNavigatinonPage: IOneNavigationPage = {
				id: pagesSlice.currentPage.page.id,
				parentPageId: pagesSlice.currentPage.page.parentPageId,
				name: pagesSlice.currentPage.page.displayName,
				path: pagesSlice.currentPage.page.path,
				icon: pagesSlice.currentPage.page.icon,
				actions: determinePageMenuActions(),
				onClick: (p) => onPageItemClick(p),
				children: [],
				securityGroups: pagesSlice.currentPage.page.securityGoups,
			};
			setSelectedItem(mappedNavigatinonPage);
		}
	}, [pagesSlice.currentPage]);

	const determinePageMenuActions = () => {
		let result: IOneNavigationPageMenuAction[] = [];
		if (hasAccess(userSlice.currentUser.data, [PermissionCodes.ManageReportingPortalPages])) {
			result = [
				{
					name: intl.formatMessage({
						id: "oneNavigation.action.add",
						defaultMessage: "Pagina aanmaken",
					}),
					icon: "Add",
					action: (p) => onPageMenuItemAddClick(p),
				},
				{
					name: intl.formatMessage({
						id: "oneNavigation.action.edit",
						defaultMessage: "Pagina bewerken",
					}),
					icon: "Edit",
					action: (p) => onPageMenuItemEditClick(p),
				},
				{
					name: intl.formatMessage({
						id: "oneNavigation.action.delete",
						defaultMessage: "Pagina verwijderen",
					}),
					icon: "Delete",
					action: (p) => onPageMenuItemDeleteClick(p),
				},
			];
		}

		return result;
	};

	const onPageMenuItemAddClick = (p: IOneNavigationPage) => {
		onAddPageClick(p);
	};

	const onPageMenuItemEditClick = (p: IOneNavigationPage) => {
		setPageToUpdate(p);
		setAddModalIsOpen(true);
	};

	const onPageMenuItemDeleteClick = (p: IOneNavigationPage) => {
		setPageToDelete(p);
	};

	const onAddPageClick = (parent?: IOneNavigationPage) => {
		setAddModalParentPage(parent);
		setAddModalIsOpen(true);
	};

	const onPageItemClick = (p: IOneNavigationPage) => {
		if (history.location.pathname !== p.path) {
			let splittedPath = p.path.split("/");
			let encodedPathPieces = splittedPath.map((piece) => encodeURIComponent(piece));

			history.push(encodedPathPieces.join("/"));
		}
	};

	const mapPages = (input: IPortalPage[]) => {
		const result: IOneNavigationPage[] = [];
		for (let i = 0; i < input.length; i++) {
			const portalPage = input[i];

			let mappedNavigatinonPage: IOneNavigationPage = {
				id: portalPage.id,
				parentPageId: portalPage.parentPageId,
				name: portalPage.displayName,
				path: portalPage.path,
				icon: portalPage.icon,
				actions: determinePageMenuActions(),
				onClick: (p) => onPageItemClick(p),
				children: [],
				securityGroups: portalPage.securityGoups,
			};

			if (portalPage.childPages && portalPage.childPages.length > 0) {
				mappedNavigatinonPage.children = mapPages(portalPage.childPages);
			}

			result.push(mappedNavigatinonPage);
		}

		return result;
	};

	const renderMenuFooter = () => {
		if (hasAccess(userSlice.currentUser.data, [PermissionCodes.ManageReportingPortalPages])) {
			return (
				<div className="action">
					<ActionButton iconProps={{ iconName: "Add" }} onClick={() => onAddPageClick()}>
						<FormattedMessage id="oneNavigation.footer.add" />
					</ActionButton>
				</div>
			);
		}

		return <></>;
	};

	useEffect(() => {
		let mappedPages = mapPages(pagesSlice.pages.data);
		setPages(mappedPages);
	}, [pagesSlice.pages]);

	return (
		<>
			<OneNavigation
				pages={pages}
				isLoading={
					pagesSlice.pages.fetchStatus === SlicePieceStatus.IsFetching ||
					pagesSlice.pages.updateStatus === SlicePieceStatus.IsFetching
				}
				mobileView={applicationContextSlice.isOnMobile}
				mobilePanelIsOpen={_props.mobilePanelIsOpen}
				setMobilePanelIsOpen={_props.setMobilePanelIsOpen}
				selectedItem={selectedItem}
				setSelectedItem={setSelectedItem}
				footer={renderMenuFooter()}
				hasEditPermissions={hasAccess(userSlice.currentUser.data, [PermissionCodes.ManageReportingPortalPages])}
				updatePageOrder={(draggedElementId, newParentId, order) => {
					dispatch(updatePortalPageOrder(draggedElementId, newParentId, order));
				}}
			/>

			<UpsertPageModal
				pageToUpdate={pageToUpdate}
				setPageToUpdate={setPageToUpdate}
				isOpen={addModalIsOpen}
				setIsOpen={setAddModalIsOpen}
				parentPage={addModalParentPage}
				setParentPage={setAddModalParentPage}
			/>

			<PageDeleteDialog pageToDelete={pageToDelete} setPageToDelete={setPageToDelete}></PageDeleteDialog>
		</>
	);
};
