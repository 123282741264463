import { ISetting, ISubscription, SubscriptionStatus } from "@one/core";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { TenantSubscriptionStatus } from "../../models/enums/TenantSubscriptionStatus";
import { msalOneFetch } from "../../MSALConfig";
import { OneThemes } from "../../styles/OneThemeProvider";
import { ApplicationContextTypes, ApplicationContextState } from "./types";

export const resetApplicationSettingsUpdateStatus = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: ApplicationContextTypes.RESET_APPLICATION_SETTINGS_UPDATE_STATUS,
		});
	};
};

export const setTheme = (newTheme: OneThemes) => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: ApplicationContextTypes.SET_THEME,
			payload: newTheme,
		});
	};
};

export const setIsOnMobile = (isOnMobile: boolean) => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: ApplicationContextTypes.SET_IS_ON_MOBILE,
			payload: isOnMobile,
		});
	};
};

export const updateApplicationSettings: ActionCreator<ThunkAction<Promise<any>, ApplicationContextState, null, any>> = (
	newSettings: ISetting[]
) => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: ApplicationContextTypes.UPDATE_APPLICATION_SETTINGS_STARTED,
		});
		try {
			const response: Response = await msalOneFetch(
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/applicationSettings`,
				{
					method: "POST",
					body: JSON.stringify({
						settings: newSettings,
					}),
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204 && response.status !== 201) {
					dispatch({ type: ApplicationContextTypes.UPDATE_APPLICATION_SETTINGS_FAILED });
					return;
				}

				const result = await response.json();

				dispatch({
					type: ApplicationContextTypes.UPDATE_APPLICATION_SETTINGS_SUCCESS,
					payload: result.settings,
				});
			}
		} catch (e) {
			console.log(e);

			dispatch({ type: ApplicationContextTypes.UPDATE_APPLICATION_SETTINGS_FAILED });
		}
	};
};

export const fetchSettings: ActionCreator<ThunkAction<Promise<any>, ApplicationContextState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: ApplicationContextTypes.FETCH_SETTINGS_STARTED,
		});
		try {
			const response: Response = await msalOneFetch(`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/settings`, {});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch({ type: ApplicationContextTypes.FETCH_SETTINGS_FAILURE });
					return;
				}

				const result = await response.json();

				dispatch({
					type: ApplicationContextTypes.FETCH_SETTINGS_SUCCESS,
					payload: {
						settings: result,
					},
				});
			}
		} catch (e) {
			dispatch({ type: ApplicationContextTypes.FETCH_SETTINGS_FAILURE });
		}
	};
};

// ---===[ TenantSubscription ]===---
export const checkTenantSubscription: ActionCreator<ThunkAction<Promise<any>, ApplicationContextState, null, any>> =
	() => {
		return async (dispatch: Dispatch) => {
			dispatch({ type: ApplicationContextTypes.CHECK_TENANT_SUBSCRIPTION_STARTED });
			try {
				const response: Response = await msalOneFetch(
					`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/tenant/checkSubscription`,
					{}
				);

				const jsonResponse: ISubscription = await response.json();
				if (response.status === 200) {

					if (jsonResponse.currentStatus === SubscriptionStatus.Active) {
						dispatch({
							type: ApplicationContextTypes.CHECK_TENANT_SUBSCRIPTION_SUCCESS,
							payload: TenantSubscriptionStatus.Active,
						});

						return;
					}
				}

				if (jsonResponse.subscriptionType === null) {
					dispatch({
						type: ApplicationContextTypes.CHECK_TENANT_SUBSCRIPTION_SUCCESS,
						payload: TenantSubscriptionStatus.None,
					});
				} else if (new Date(jsonResponse.endDate) < new Date() || new Date(jsonResponse.startDate) > new Date()) {
					dispatch({
						type: ApplicationContextTypes.CHECK_TENANT_SUBSCRIPTION_SUCCESS,
						payload: TenantSubscriptionStatus.Inactive,
					});
				} else {
					dispatch({
						type: ApplicationContextTypes.CHECK_TENANT_SUBSCRIPTION_SUCCESS,
						payload: TenantSubscriptionStatus.Error,
					});
				}
				
			} catch (e) {
				dispatch({ type: ApplicationContextTypes.CHECK_TENANT_SUBSCRIPTION_FAILED });
			}
		};
	};
