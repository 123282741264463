import * as React from "react";
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    MessageBar,
    MessageBarType,
    PrimaryButton,
} from "@fluentui/react";
import { IOneNavigationPage, PortalPageStatus, SlicePieceStatus } from "@one/core";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { updatePortalPageStatus } from "../../../../store/pages/actions";
import { useEffect } from "react";
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { PagesActionTypes } from "../../../../store/pages/types";

export interface IPageDeleteDialogProps {
    pageToDelete: IOneNavigationPage | null;
    setPageToDelete: (value: IOneNavigationPage | null) => void;
}

export const PageDeleteDialog = (_props: IPageDeleteDialogProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { pagesSlice } = useSelector((state: IApplicationState) => state);

    const hideDialog = () => {
        _props.setPageToDelete(null);
    };

    const confirmPageDelete = () => {
        dispatch(
            updatePortalPageStatus({
                id: _props.pageToDelete?.id,
                status: PortalPageStatus.Deleted,
            })
        );
    };

    useEffect(() => {
        dispatch({ type: PagesActionTypes.RESET_PAGES_STATUSSES });
    }, []);

    useEffect(() => {
        if (pagesSlice.pages.deleteStatus === SlicePieceStatus.Success) {
            _props.setPageToDelete(null);
        }
    }, [pagesSlice.pages.deleteStatus]);

    const deleteDialogContentProps = {
        type: DialogType.largeHeader,
        title: intl.formatMessage({
            id: "oneNavigation.action.delete.title",
            defaultMessage: "Pagina verwijderen bevestigen",
        }),
        subText: intl.formatMessage({
            id: "oneNavigation.action.delete.subtext",
            defaultMessage:
                "Na het verwijderen zal de pagina zal niet meer toegankelijk zijn. Weet je zeker dat je deze pagina wilt verwijderen?",
        }),
    };

    return (
        <Dialog
            hidden={_props.pageToDelete === null}
            onDismiss={hideDialog}
            dialogContentProps={deleteDialogContentProps}
            modalProps={{ isBlocking: true }}
        >
            {pagesSlice.pages.deleteStatus === SlicePieceStatus.Error && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                    dismissButtonAriaLabel="Close"
                    styles={{ root: { marginBottom: "10px" } }}
                >
                    <FormattedMessage id="oneNavigation.action.delete.error" />
                </MessageBar>
            )}

            <DialogFooter>
                <PrimaryButton
                    onClick={confirmPageDelete}
                    text={intl.formatMessage({
                        id: "general.confirm",
                        defaultMessage: "Bevestigen",
                    })}
                />
                <DefaultButton
                    onClick={() => hideDialog()}
                    text={intl.formatMessage({
                        id: "general.cancel",
                        defaultMessage: "Annuleren",
                    })}
                />
            </DialogFooter>
        </Dialog>
    );
};
