import React from "react";
import { OnePanel, OnePanelContent } from "@one/web";
import { hasValue } from "@one/core";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { ActionButton, Persona, PersonaSize, IPersonaProps } from "@fluentui/react";

import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import { fetchUserPhotoUrl } from "../../../store/user/actions";
import { useIntl } from "react-intl";

export interface AccountPanelProps {
	isOpen: boolean;
	onDismiss: () => void;
}

export const AccountPanel = (props: AccountPanelProps) => {
	const intl = useIntl();
	const { instance } = useMsal();
	const { userSlice } = useSelector((state: IApplicationState) => state);
	const [userImage, setUserImage] = React.useState<string | null>(null);

	const myProfileUrl =
		userSlice.currentUser.data.tenant !== undefined
			? `https://${userSlice.currentUser.data.tenant.name}-my.sharepoint.com/person.aspx`
			: "";

	React.useEffect(() => {
		const fetchUserImage = async () => {
			try {
				if (userSlice.currentUser.data.objectId !== null) {
					const userImageUrl = await fetchUserPhotoUrl(userSlice.currentUser.data.objectId);
					setUserImage(userImageUrl);
				}
			} catch (e) {
				console.log(e);
			}
		};

		if (userSlice.currentUser.data.objectId !== undefined) {
			fetchUserImage();
		}

		return () => {
			setUserImage(null);
		};
	}, [userSlice.currentUser.data.objectId]);

	const logout = () => {
		instance.logoutRedirect().catch((e) => {
			console.error(e);
		});
	};

	const renderPersona = () => {
		const user = userSlice.currentGraphUser.data;
		if (user !== null) {
			const persona = {
				...user,
				text: user.displayName,
				showSecondaryText: true,
				secondaryText: user.jobTitle,
				imageUrl: userImage,
			} as IPersonaProps;
			return (
				<Persona
					size={PersonaSize.size48}
					{...persona}
					styles={{ primaryText: { overflow: "visible" }, root: { marginBottom: "10px" } }}
				/>
			);
		}
		return null;
	};

	return (
		<OnePanel
			isOpen={props.isOpen}
			onDismiss={props.onDismiss}
			headerText={intl.formatMessage({ id: "accountPanel.myAccount", defaultMessage: "Mijn account" })}
		>
			<OnePanelContent>
				{renderPersona()}

				<ActionButton
					iconProps={{ iconName: "PlayerSettings" }}
					text={intl.formatMessage({ id: "accountPanel.myProfile", defaultMessage: "Mijn profiel" })}
					href={myProfileUrl}
					target="_blank"
					disabled={!hasValue(myProfileUrl)}
				/>
				<ActionButton
					iconProps={{ iconName: "SignOut" }}
					text={intl.formatMessage({
						id: "accountPanel.logout",
						defaultMessage: "Uitloggen",
					})}
					onClick={logout}
				/>
			</OnePanelContent>
		</OnePanel>
	);
};
