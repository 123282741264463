import { IOneUser, SlicePieceStatus } from "@one/core";
import { UserActionTypes, UserState } from "./types";

const initialState: UserState = {
	currentUser: {
		data: {} as IOneUser,
		fetchStatus: SlicePieceStatus.None,
	},
	currentGraphUser: {
		data: null,
		fetchStatus: SlicePieceStatus.None,
	},
};

export function userReducer(state = initialState, action: { type: UserActionTypes; payload: any }): UserState {
	switch (action.type) {
		case UserActionTypes.FETCH_CURRENT_USER_STARTED:
			return {
				...state,
				currentUser: {
					...state.currentUser,
					fetchStatus: SlicePieceStatus.IsFetching,
				},
			};
		case UserActionTypes.FETCH_CURRENT_USER_SUCCESS:
			return {
				...state,
				currentUser: {
					...state.currentUser,
					data: action.payload,
					fetchStatus: SlicePieceStatus.Success,
				},
			};
		case UserActionTypes.FETCH_CURRENT_USER_FAILURE:
			return {
				...state,
				currentUser: {
					...state.currentUser,
					fetchStatus: SlicePieceStatus.Error,
				},
			};

		case UserActionTypes.FETCH_CURRENT_GRAPH_USER_STARTED:
			return {
				...state,
				currentGraphUser: {
					...state.currentGraphUser,
					status: SlicePieceStatus.IsFetching,
				},
			};
		case UserActionTypes.FETCH_CURRENT_GRAPH_USER_SUCCESS:
			return {
				...state,
				currentGraphUser: {
					...state.currentGraphUser,
					data: action.payload,
					fetchStatus: SlicePieceStatus.Success,
				},
			};
		case UserActionTypes.FETCH_CURRENT_GRAPH_USER_FAILURE:
			return {
				...state,
				currentGraphUser: {
					...state.currentGraphUser,
					fetchStatus: SlicePieceStatus.Error,
				},
			};

		default:
			return state;
	}
}
