import { ITooltipHostStyles, IconButton, TooltipHost } from '@fluentui/react';
import { IPortalPageColumnPowerBi, PortalPagePowerBiType } from '@one/core';
import { Card, CardContent } from '@one/web';
import { PowerBIEmbed } from 'powerbi-client-react';
import { Report } from 'powerbi-client';
import { TokenType } from 'powerbi-models';
import * as React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { getPowerBiAccessToken } from '../../../../../MSALConfig';
import './PowerBiEmbedColumn.scss';

export interface IPortalPageColumnPowerBiProps {
    column: IPortalPageColumnPowerBi;
}

export const PowerBiEmbedColumn = (props: IPortalPageColumnPowerBiProps) => {
    const { column } = props;
    const intl = useIntl();
    const [powerBiAccessToken, setPowerBiAccessToken] = React.useState<string | null>(null);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [report, setReport] = useState<Report>();
    const calloutProps = { gapSpace: 0 };
    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

    React.useEffect(() => {
        const goGetPowerBiAccessToken = async () => {
            const authResult = await getPowerBiAccessToken();
            setPowerBiAccessToken(authResult.accessToken);
        };
        goGetPowerBiAccessToken();
    }, []);

    if (column.workspace === null || column.resource === null) {
        return null;
    }

    const printPowerBi = async () => {
        try {
            await report?.print();
        } catch (errors) {
            console.log(errors);
        }
    };

    const fullscreenPowerBi = async () => {
        try {
            await report?.fullscreen();
        } catch (errors) {
            console.log(errors);
        }
    };

    return (
        <Card className="portal-page-column" style={{ height: '78vh', width: `${column.width || 100}%` }}>
            <CardContent>
                {column.resourceType === PortalPagePowerBiType.Report && (
                    <div className="portal-page-column-powerbi-actions">
                        <TooltipHost
                            content={intl.formatMessage({
                                id: 'portalPage.powerbi.report.print.label',
                            })}
                            id={'print'}
                            calloutProps={calloutProps}
                            styles={hostStyles}
                            setAriaDescribedBy={false}
                        >
                            <IconButton iconProps={{ iconName: 'Print' }} aria-label="Print" onClick={() => printPowerBi()} />
                        </TooltipHost>

                        <TooltipHost
                            content={intl.formatMessage({
                                id: 'portalPage.powerbi.report.fullscreen.label',
                            })}
                            id={'Fullscreen'}
                            calloutProps={calloutProps}
                            styles={hostStyles}
                            setAriaDescribedBy={false}
                        >
                            <IconButton
                                iconProps={{ iconName: 'Fullscreen' }}
                                aria-label="Fullscreen"
                                onClick={() => fullscreenPowerBi()}
                            />
                        </TooltipHost>
                    </div>
                )}
                {powerBiAccessToken !== null && (
                    <PowerBIEmbed
                        eventHandlers={
                            new Map([
                                [
                                    'loaded',
                                    function () {
                                        setHasLoaded(true);
                                    },
                                ],
                            ])
                        }
                        embedConfig={{
                            type: column.resourceType, // Supported types: report, dashboard, tile, visual and qna
                            id: column.resource.id,
                            embedUrl: column.resource.embedUrl.split('&config=')[0],
                            accessToken: powerBiAccessToken,
                            tokenType: TokenType.Aad,
                            settings: {
                                bars: {
                                    actionBar: {
                                        visible: false,
                                    },
                                },
                                panes: {
                                    filters: {
                                        expanded: false,
                                        visible: false,
                                    },
                                    pageNavigation: {
                                        position: 0,
                                    },
                                },
                            },
                        }}
                        cssClassName="portal-page-column-powerbi"
                        getEmbeddedComponent={(embeddedReport) => {
                            setReport(embeddedReport as unknown as Report);
                        }}
                    />
                )}
            </CardContent>
        </Card>
    );
};
