import "react-app-polyfill/ie9";
import React from "react";
import { history } from "./store/index";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store/index";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { msalInstance } from "./MSALConfig";
import { ConnectedRouter } from "connected-react-router";
import { Route } from "react-router-dom";
import { App } from "./App";
import { UnAuthenticated } from "./components/UnAuthenticated";

const store = configureStore();

ReactDOM.render(
	<MsalProvider instance={msalInstance}>
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<AuthenticatedTemplate>
					<Route component={App} />
				</AuthenticatedTemplate>
				<UnauthenticatedTemplate>
					<UnAuthenticated></UnAuthenticated>
				</UnauthenticatedTemplate>
			</ConnectedRouter>
		</Provider>
	</MsalProvider>,
	document.getElementById("root")
);
