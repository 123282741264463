import {
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import { hasValue, PortalPageColumnType } from "@one/core";
import * as React from "react";
import { useState } from "react";
import { useIntl } from "react-intl";

export interface IAddWebpartDialogProps {
  isOpen: boolean;
  onSelect: (type: PortalPageColumnType) => void;
  onDismiss: () => void;
}

export const AddWebpartDialog = (props: IAddWebpartDialogProps) => {
  const intl = useIntl();
  const [selectedType, setSelectedType] = useState<
    PortalPageColumnType | undefined
  >(undefined);
  const [webpartDescription, setWebpartDescription] = useState("");

  React.useEffect(() => {
    if (selectedType !== undefined) {
      switch (selectedType) {
        case PortalPageColumnType.PowerBi:
          setWebpartDescription(
            intl.formatMessage({
              id: "portalPage.addWebpart.powerbi.description",
            })
          );
          break;
        case PortalPageColumnType.Text:
          setWebpartDescription(
            intl.formatMessage({ id: "portalPage.addWebpart.text.description" })
          );
          break;
      }
    }
  }, [selectedType]);
  return (
    <Dialog
      hidden={!props.isOpen}
      onDismiss={props.onDismiss}
      dialogContentProps={{
        type: DialogType.largeHeader,
        title: intl.formatMessage({ id: "portalPage.addWebpart.add" }),
      }}
    >
      <ChoiceGroup
        selectedKey={selectedType?.toString()}
        onChange={(_e, option) => {
          if (option !== undefined) {
            setSelectedType(parseInt(option.key) as PortalPageColumnType);
          }
        }}
        styles={{
          flexContainer: {
            "> div": {
              width: "calc(50% - 4px)",
              "> div": {
                width: "100%",
              },
            },
          },
        }}
        options={[
          {
            key: PortalPageColumnType.Text.toString(),
            text: intl.formatMessage({
              id: "portalPage.addWebpart.text.label",
            }),
            iconProps: {
              iconName: "PlainText",
            },
          },
          {
            key: PortalPageColumnType.PowerBi.toString(),
            text: intl.formatMessage({
              id: "portalPage.addWebpart.powerbi.label",
            }),
            iconProps: {
              iconName: "PowerBILogo",
            },
          },
        ]}
      />

      {hasValue(webpartDescription) && (
        <p style={{ margin: "10px 0 20px 0", width: "240px" }}>
          {webpartDescription}
        </p>
      )}

      <DialogFooter>
        <DefaultButton
          text={intl.formatMessage({ id: "general.cancel" })}
          onClick={() => props.onDismiss()}
        />
        <PrimaryButton
          text={intl.formatMessage({ id: "general.add" })}
          disabled={selectedType === undefined}
          onClick={() => {
            if (selectedType !== undefined) {
              props.onSelect(selectedType);
            }
          }}
        />
      </DialogFooter>
    </Dialog>
  );
};
