import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { OnePanel, OnePanelContent } from "@one/web";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";

import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import {
  resetApplicationSettingsUpdateStatus,
  updateApplicationSettings,
} from "../../../store/application/actions";
import { OneThemes } from "../../../styles/OneThemeProvider";
import { hasValue, ISetting, SlicePieceStatus } from "@one/core";

export interface SettingsPanelProps {
  isOpen: boolean;
  onDismiss: () => void;
}

export const SettingsPanel = (props: SettingsPanelProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { applicationContextSlice } = useSelector(
    (state: IApplicationState) => state
  );

  const isFetching =
    applicationContextSlice.settings.updateStatus ===
    SlicePieceStatus.IsFetching;

  // Reset the error messages when the panel toggles and there are errors still present.
  React.useEffect(() => {
    if (
      applicationContextSlice.settings.updateStatus === SlicePieceStatus.Error
    ) {
      dispatch(resetApplicationSettingsUpdateStatus());
    }
  }, [props.isOpen]);

  const renderThemeOptions = () => {
    return [
      {
        key: OneThemes.Default,
        text: intl.formatMessage({ id: "settingsPanel.theme.option.default" }),
      },
      {
        key: OneThemes.Green,
        text: intl.formatMessage({ id: "settingsPanel.theme.option.green" }),
      },
      {
        key: OneThemes.Orange,
        text: intl.formatMessage({ id: "settingsPanel.theme.option.orange" }),
      },
      {
        key: OneThemes.Red,
        text: intl.formatMessage({ id: "settingsPanel.theme.option.red" }),
      },
    ] as IDropdownOption[];
  };

  const createValidationSchema = () => {
    let yupObject: any = {
      // name: Yup.string().required(
      // 	intl.formatMessage({
      // 		id: "general.required",
      // 		defaultMessage: "Dit veld is verplicht.",
      // 	})
      // ),
    };
    return Yup.object().shape(yupObject);
  };

  const _submitForm = async (formValues) => {
    const displayName = hasValue(formValues.displayName)
      ? formValues.displayName.toString()
      : null;
    const imageUrl = hasValue(formValues.imageUrl)
      ? formValues.imageUrl.toString()
      : null;
    dispatch(
      updateApplicationSettings([
        {
          key: "OneForReporting_theme",
          value: formValues.theme.toString(),
        } as ISetting,
        {
          key: "OneForReporting_displayName",
          value: displayName,
        } as ISetting,
        {
          key: "OneForReporting_imageUrl",
          value: imageUrl,
        } as ISetting,
      ])
    );
  };

  const initialValues = {
    theme: applicationContextSlice.theme,
    displayName: applicationContextSlice.displayName,
    imageUrl: applicationContextSlice.imageUrl,
  };

  return (
    <OnePanel
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      headerText={intl.formatMessage({ id: "settingsPanel.header" })}
    >
      {applicationContextSlice.settings.updateStatus ===
        SlicePieceStatus.Error && (
        <MessageBar messageBarType={MessageBarType.error}>
          <FormattedMessage id="settingsPanel.save.error" />
        </MessageBar>
      )}
      <OnePanelContent>
        <Formik
          initialValues={initialValues}
          onSubmit={(valuesToSubmit, { setSubmitting }) => {
            _submitForm(valuesToSubmit);
            setSubmitting(false);
          }}
          validationSchema={() => createValidationSchema()}
        >
          {(formikProps) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = formikProps;
            return (
              <form onSubmit={handleSubmit} style={{ lineHeight: "normal" }}>
                <Stack
                  tokens={{
                    childrenGap: 20,
                  }}
                >
                  <Stack
                    tokens={{
                      childrenGap: 10,
                    }}
                  >
                    <TextField
                      id="displayName"
                      value={values.displayName || ""}
                      label={intl.formatMessage({
                        id: "settingsPanel.portal.name.label",
                      })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={intl.formatMessage({
                        id: "settingsPanel.portal.name.placeholder",
                      })}
                      disabled={isFetching}
                    />

                    <Dropdown
                      options={renderThemeOptions()}
                      id="theme"
                      label={intl.formatMessage({
                        id: "settingsPanel.portal.theme.label",
                      })}
                      selectedKey={values.theme}
                      onChange={(_e, option) =>
                        setFieldValue("theme", option?.key)
                      }
                      onBlur={handleBlur}
                      errorMessage={
                        errors.theme && touched.theme ? errors.theme : undefined
                      }
                      className={errors.theme && touched.theme ? "error" : ""}
                      disabled={isFetching}
                    />
                    <TextField
                      id="imageUrl"
                      value={values.imageUrl || ""}
                      label={intl.formatMessage({
                        id: "settingsPanel.portal.imageUrl.label",
                      })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={intl.formatMessage({
                        id: "settingsPanel.portal.imageUrl.placeholder",
                      })}
                      disabled={isFetching}
                    />
                    {hasValue(values.imageUrl) && (
                      <>
                        <p>
                          <FormattedMessage id="settingsPanel.portal.imageUrl.preview" />
                          :
                        </p>
                        <img
                          src={values.imageUrl || ""}
                          alt={intl.formatMessage({
                            id: "settingsPanel.portal.imageUrl.notFound",
                          })}
                          width={"100%"}
                        />
                      </>
                    )}

                    {values.theme !== initialValues.theme && (
                      <MessageBar messageBarType={MessageBarType.warning}>
                        <FormattedMessage id="settingsPanel.refreshNeeded" />
                      </MessageBar>
                    )}
                  </Stack>

                  <PrimaryButton type="submit" disabled={isFetching}>
                    <FormattedMessage id="general.save" />
                  </PrimaryButton>
                </Stack>
              </form>
            );
          }}
        </Formik>
      </OnePanelContent>
    </OnePanel>
  );
};
