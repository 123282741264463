import { Breadcrumb, DefaultButton, IBreadcrumbItem, IconButton, PrimaryButton } from "@fluentui/react";
import { hasAccess, IPortalPage, PermissionCodes, SlicePieceStatus } from "@one/core";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CurrentPageActionToExecute } from "../../../models/enums/CurrentPageActionToExecute";
import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import { setActionToExecute, setCurrentPage, setEditMode } from "../../../store/pages/actions";
import { PortalPageContent } from "./portalPageContent/PortalPageContent";
import { PortalPageEditMode } from "./portalPageEditMode/PortalPageEditMode";
import "./PortalPage.scss";

export interface IPortalPageProps {
	portalPage: IPortalPage;
}

export const PortalPage = (props: IPortalPageProps) => {
	const intl = useIntl();
	const history = useHistory();
	const dispatch = useDispatch();
	const { pagesSlice, userSlice, applicationContextSlice } = useSelector((state: IApplicationState) => state);
	const [breadCrumbItems, setBreadCrumbItems] = useState<IBreadcrumbItem[]>([]);

	const currentPage = pagesSlice.currentPage;

	// ---===[ UseEffects ]===---
	// On init
	React.useEffect(() => {
		if (pagesSlice.currentPage.page == null || pagesSlice.currentPage.page.id !== props.portalPage.id) {
			dispatch(setCurrentPage(props.portalPage));
		}

		if (pagesSlice.currentPage.isInEditMode) {
			dispatch(setEditMode(false));
		}

		const newBreadCrumbItems: IBreadcrumbItem[] = [];

		const fillBreadCrumb = (input: IPortalPage) => {
			newBreadCrumbItems.push({
				key: input.id.toString(),
				text: input.displayName,
				onClick: () => history.push(input.path),
			});
			if (input.parentPageId) {
				const parentPage = pagesSlice.pagesFlatList.filter((p) => p.id === input.parentPageId)[0];
				if (parentPage !== undefined) {
					fillBreadCrumb(parentPage);
				}
			}
		};

		fillBreadCrumb(props.portalPage);
		newBreadCrumbItems.reverse();
		setBreadCrumbItems(newBreadCrumbItems);
	}, [props.portalPage]);

	const renderActionButtons = () => {
		if (hasAccess(userSlice.currentUser.data, [PermissionCodes.ManageReportingPortalPages])) {
			return (
				<>
					{currentPage.isInEditMode ? (
						<div className="portal-page-header-actions">
							<PrimaryButton
								iconProps={{
									iconName: "CheckMark",
								}}
								styles={{
									root: {
										marginRight: "15px",
									},
								}}
								text={intl.formatMessage({
									id: "general.save",
								})}
								type="submit"
								disabled={pagesSlice.currentPage.updateContentStatus === SlicePieceStatus.IsFetching}
								onClick={() => dispatch(setActionToExecute(CurrentPageActionToExecute.Save))}
							/>
							<DefaultButton
								iconProps={{
									iconName: "Cancel",
								}}
								disabled={pagesSlice.currentPage.updateContentStatus === SlicePieceStatus.IsFetching}
								text={intl.formatMessage({
									id: "portalPage.edit.discard.button.label",
								})}
								onClick={() => dispatch(setEditMode(false))}
							/>
						</div>
					) : (
						<>
							{applicationContextSlice.isOnMobile ? (
								<IconButton
									iconProps={{
										iconName: "Edit",
									}}
									onClick={() => dispatch(setEditMode(true))}
								/>
							) : (
								<PrimaryButton
									iconProps={{
										iconName: "Edit",
									}}
									text={intl.formatMessage({
										id: "portalPage.edit.enterEditMode.button.label",
									})}
									onClick={() => dispatch(setEditMode(true))}
								/>
							)}
						</>
					)}
				</>
			);
		}
		return null;
	};

	// ---===[ Rendering ]===---
	if (currentPage.page === null) {
		return null;
	}

	return (
		<div className="portal-page">
			<div
				className="portal-page-header"
				style={currentPage.isInEditMode && applicationContextSlice.isOnMobile ? { flexDirection: "column" } : undefined}
			>
				<Breadcrumb
					items={breadCrumbItems}
					ariaLabel="Breadcrumb with items rendered as links"
					overflowAriaLabel="More links"
					maxDisplayedItems={applicationContextSlice.isOnMobile ? 3 : 5}
					styles={{
						root: {
							margin: 0,
						},
					}}
				/>

				{renderActionButtons()}
			</div>

			<div className="portal-page-content">
				{currentPage.isInEditMode ? <PortalPageEditMode /> : <PortalPageContent content={currentPage.page.content} />}
			</div>
		</div>
	);
};
