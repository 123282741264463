export default {
	// --== General ==--
	"general.makeAChoice": "Select an option",
	"general.ok": "Ok",
	"general.yes": "Yes",
	"general.save": "Save",
	"general.cancel": "Cancel",
	"general.done": "Done",
	"general.close": "Close",
	"general.add": "Add",
	"general.required": "This field is required.",
	"general.somethingWentWrong": "Something went wrong while saving.",
	"general.confirm": "Confirm",
	"general.welcome": "Welcome",
	"general.edit": "Edit",

	"general.tagpicker.noTagsFound": "No results",

	// --== Welcome ==--
	"welcome.noPagesUser": "No pages have been created yet, please contact the administrator.",
	"welcome.noPagesAdmin": "Start by creating a new page by pressing the button at the bottom of the navigation menu.",

	// --== AccountPanel ==--
	"accountPanel.myAccount": "My account",
	"accountPanel.myProfile": "My profile",
	"accountPanel.logout": "Sign out",

	// --== SettingsPanel ==--
	"settingsPanel.header": "Portal settings",
	"settingsPanel.portal.header": "Portal",
	"settingsPanel.portal.theme.label": "Theme",
	"settingsPanel.portal.imageUrl.label": "Logo",
	"settingsPanel.portal.imageUrl.placeholder": "Enter a link to an image.",
	"settingsPanel.portal.imageUrl.preview": "Preview",
	"settingsPanel.portal.name.label": "Name",
	"settingsPanel.portal.name.placeholder": "E.g. One for Reporting",
	"settingsPanel.refreshNeeded":
		"The application will need to be refreshed in order for this to take effect, any unsaved changes to pages will be lost.",
	"settingsPanel.theme.option.default": "Blue",
	"settingsPanel.theme.option.green": "Green",
	"settingsPanel.theme.option.orange": "Orange",
	"settingsPanel.theme.option.red": "Red",

	"settingsPanel.save.error": "Could not save the settings.",

	// --== Subscription ==--
	"subscription.inactive": "Your subscription has expired, please contact your administrator",
	"subscription.none": "You don't have a subscription for One.",
	"subscription.error": "Something went wrong while retrieving your subscription.",

	// --== oneNavigation ==--
	"oneNavigation.footer.add": "Add new page",
	"oneNavigation.action.add": "Add page",
	"oneNavigation.action.edit": "Edit page",
	"oneNavigation.action.delete": "Delete page",
	"oneNavigation.action.delete.title": "Confirm page delete",
	"oneNavigation.action.delete.subtext":
		"After deleting, the page is no longer accessable. Are you sure you want to delete this page?",
	"oneNavigation.action.delete.error": "An error occurred while deleting this page.",
	"oneNavigation.action.create.error": "An error occurred while creating a page.",
	"oneNavigation.action.update.error": "An error occurred while updating this page.",

	// --== AddModalPage ==--
	"addModalPage.title": "Add page",
	"addModalPage.subtext": "Create a new page to show a new report overview.",
	"addModalPage.dialog.input.pageName": "Page name",
	"addModalPage.dialog.input.icon": "FluentUi Icon",
	"addModalPage.dialog.input.securityGroups": "Which groups are allowed to this page?",
	"addModalPage.dialog.teachingBubble.headline": "Page access",
	"addModalPage.dialog.teachingBubble.text":
		"Allows you to control who can see and access the page. If you don't check any groups, everyone has access to the page.",


	// PortalPage
	"portalPage.noContent": "This page does not have any content, please contact your administrator.",
	"portalPage.powerbi.report.print.label": "Print",
	"portalPage.powerbi.report.fullscreen.label": "Fullscreen",

	// --== PortalPageEditMode ==--
	"portalPage.addWebpart.add": "Add webpart",
	"portalPage.addWebpart.powerbi.label": "PowerBi",
	"portalPage.addWebpart.powerbi.description": "Embed a PowerBi report or dashboard",
	"portalPage.addWebpart.text.label": "Text",
	"portalPage.addWebpart.text.description": "A RichText editor",

	"portalPage.edit.configuration.required": "Configuration required",

	"portalPage.edit.enterEditMode.button.label": "Edit",
	"portalPage.edit.discard.button.label": "Discard changes",
	"portalPage.edit.description.label": "Description",
	"portalPage.edit.powerbi.label": "PowerBi",

	"portalPage.edit.embed.type.label": "Type",
	"portalPage.edit.embed.type.report": "Report",
	"portalPage.edit.embed.type.dashboard": "Dashboard",
	"portalPage.edit.embed.workspace.label.report": "Choose the workspace that contains the report",
	"portalPage.edit.embed.workspace.label.dashboard": "Choose the workspace that contains the dashboard",
	"portalPage.edit.embed.workspace.placeholder": "Choose or enter the workspace name",

	"portalPage.edit.embed.report.label": "Select the report you'd like to embed",
	"portalPage.edit.embed.report.placeholder": "Choose or enter the report name",
	"portalPage.edit.embed.dashboard.label": "Select the dashboard you'd like to embed",
	"portalPage.edit.embed.dashboard.placeholder": "Choose or enter the dashboard name",
};
