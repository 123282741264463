import {
  ChoiceGroup,
  CommandBar,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  ITag,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  TagPicker,
  useTheme,
} from "@fluentui/react";
import {
  filterSuggestedTags,
  hasValue,
  IPortalPageColumnPowerBi,
  IPortalPageContent,
  IPortalPageRow,
  IPowerBiDashboard,
  IPowerBiReport,
  listContainsTagList,
  PortalPagePowerBiType,
  SlicePieceStatus,
} from "@one/core";
import { Card, CardContent } from "@one/web";
import { FormikProps } from "formik";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";
import {
  fetchDashboardsForWorkspaceId,
  fetchReportsForWorkspaceId,
} from "../../../../../store/admin/actions";
// import { PortalPageEditModeForm } from "../PortalPageEditMode";

export interface IPowerBiEmbedProps {
  formikProps: FormikProps<IPortalPageContent>;
  column: IPortalPageColumnPowerBi;
  rowIndex: number;
}

export const PowerBiEmbedColumnEdit = (props: IPowerBiEmbedProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { column } = props;
  const { adminSlice, pagesSlice } = useSelector(
    (state: IApplicationState) => state
  );
  const { values, setFieldValue } = props.formikProps;
  const [panelIsOpen, setPanelIsOpen] = React.useState(false);

  const configurationRequired =
    !hasValue(column.workspace) || !hasValue(column.resource);
  const workspacesReadonly = hasValue(column.workspace);
  const resourcesReadonly = hasValue(column.resource);
  const resourceName = hasValue(column.resource)
    ? column.resourceType === PortalPagePowerBiType.Report
      ? (column.resource as IPowerBiReport).name
      : (column.resource as IPowerBiDashboard).displayName
    : undefined;

  const setPanelIsOpenState = (value: boolean) => {
    if (
      pagesSlice.currentPage.updateContentStatus !== SlicePieceStatus.IsFetching
    ) {
      setPanelIsOpen(value);
    }
  };

  const updateColumnIndex = (newIndex: number) => {
    let newRows = [...values.rows].map((row, index) => {
      return {
        columns: [...row.columns].map((column) => {
          return {
            ...column,
          };
        }),
      } as IPortalPageRow;
    });

    newRows[newIndex].columns[0] = { ...props.column };
    newRows[props.rowIndex].columns[0] = {
      ...values.rows[newIndex].columns[0],
    };

    setFieldValue("rows", newRows);
  };
  const updateColumn = (newColumn: any) => {
    const newRows = [...values.rows].map((row, index) => {
      if (index === props.rowIndex) {
        return {
          columns: [...row.columns].map((column) => {
            return {
              ...column,
              ...newColumn,
            };
          }),
        } as IPortalPageRow;
      } else {
        return row;
      }
    });

    setFieldValue("rows", newRows);
  };
  const removeColumn = () => {
    const newRows = [...values.rows].filter(
      (_row, index) => index !== props.rowIndex
    );
    setFieldValue("rows", newRows);
  };

  // When the selected workspace changes...
  React.useEffect(() => {
    // ... and there is a (new) value selected...
    if (hasValue(column.workspace)) {
      // ... fetch reports for this workspace
      if (column.resourceType === "report") {
        dispatch(fetchReportsForWorkspaceId(column.workspace.id));
      } else {
        dispatch(fetchDashboardsForWorkspaceId(column.workspace.id));
      }
    }
  }, [column.workspace, column.resourceType]);

  const renderSubTitle = () => {
    if (hasValue(column.workspace) && hasValue(column.resource)) {
      return (
        <p style={{ marginBottom: "15px" }}>
          {column.workspace.name} - {resourceName}
        </p>
      );
    }
    return null;
  };

  const resourceTags =
    column.resourceType === PortalPagePowerBiType.Report
      ? adminSlice.reportsForWorkspace.tags
      : adminSlice.dashboardsForWorkspace.tags;

  return (
    <Card className="portal-page-column" style={{ width: "100%" }} enableHover>
      <CommandBar
        items={[]}
        className={"webpart-edit-command-bar"}
        styles={{
          root: {
            height: "40px",
            background: theme.palette.neutralLighterAlt,
            borderBottom: "1px solid",
            borderColor: theme.palette.neutralLighter,
            button: {
              background: theme.palette.neutralLighterAlt,
            },
            cursor: "default",
          },
        }}
        farItems={[
          {
            key: "settings",
            buttonStyles: {
              icon: {
                color: theme.palette.black,
              },
            },
            iconProps: {
              iconName: "Settings",
            },
            onClick: () => setPanelIsOpenState(true),
          },
          {
            key: "moveUp",
            buttonStyles: {
              icon: {
                color: theme.palette.black,
              },
            },
            disabled: props.rowIndex === 0,
            iconProps: {
              iconName: "Up",
            },
            onClick: () => updateColumnIndex(props.rowIndex - 1),
          },
          {
            key: "moveDown",
            buttonStyles: {
              icon: {
                color: theme.palette.black,
              },
            },
            disabled:
              props.rowIndex === props.formikProps.values.rows.length - 1,
            iconProps: {
              iconName: "Down",
            },
            onClick: () => updateColumnIndex(props.rowIndex + 1),
          },
          {
            key: "remove",
            buttonStyles: {
              icon: {
                color: theme.palette.black,
              },
            },
            iconProps: {
              iconName: "Trash",
            },
            onClick: () => removeColumn(),
          },
        ]}
      />
      {configurationRequired && (
        <MessageBar messageBarType={MessageBarType.warning}>
          <FormattedMessage id="portalPage.edit.configuration.required" />
        </MessageBar>
      )}
      <CardContent onClick={() => setPanelIsOpenState(true)}>
        <div className="center-content">
          <Icon iconName="PowerBILogo" style={{ fontSize: "42px" }} />
          <h3 style={{ marginBottom: "15px" }}>
            <FormattedMessage id="portalPage.edit.powerbi.label" />
          </h3>
          {renderSubTitle()}

          <PrimaryButton
            iconProps={{ iconName: "Edit" }}
            onClick={() => setPanelIsOpenState(!panelIsOpen)}
            disabled={
              pagesSlice.currentPage.updateContentStatus ===
              SlicePieceStatus.IsFetching
            }
          >
            <FormattedMessage id="general.edit" />
          </PrimaryButton>

          <Dialog
            isOpen={panelIsOpen}
            onDismiss={() => setPanelIsOpenState(false)}
            dialogContentProps={{
              type: DialogType.largeHeader,
              title: "Power Bi settings",
            }}
          >
            <Label styles={{ root: { lineHeight: "normal" } }}>
              <FormattedMessage id="portalPage.edit.embed.type.label" />
            </Label>
            <ChoiceGroup
              selectedKey={column.resourceType}
              onChange={(_e, option) => {
                updateColumn({
                  resource: null,
                  resourceType: option?.key || PortalPagePowerBiType.Report,
                });
              }}
              options={[
                {
                  key: "report",
                  text: intl.formatMessage({
                    id: "portalPage.edit.embed.type.report",
                  }),
                  iconProps: { iconName: "ReportDocument" },
                  styles: {
                    root: { flex: "1" },
                    choiceFieldWrapper: { flex: "1" },
                    labelWrapper: { width: "100%", maxWidth: "90%" },
                  },
                },
                {
                  key: "dashboard",
                  text: intl.formatMessage({
                    id: "portalPage.edit.embed.type.dashboard",
                  }),
                  iconProps: { iconName: "ViewDashboard" },
                  styles: {
                    root: { flex: "1" },
                    choiceFieldWrapper: { flex: "1" },
                    labelWrapper: { width: "100%", maxWidth: "90%" },
                  },
                },
              ]}
            />

            {/*  TODO change translations based on report or dashbaord choice */}
            <Label styles={{ root: { lineHeight: "normal" } }}>
              <FormattedMessage id="portalPage.edit.embed.workspace.label.report" />
            </Label>

            {/* TODO selectedItems when update */}
            <TagPicker
              selectedItems={
                hasValue(column.workspace)
                  ? [
                      {
                        key: column.workspace.id,
                        name: column.workspace.name,
                      } as ITag,
                    ]
                  : []
              }
              disabled={
                adminSlice.workspaces.fetchStatus ===
                SlicePieceStatus.IsFetching
              }
              onChange={(items) => {
                if (items) {
                  let workspaceToUse: any = null;
                  if (items.length > 0) {
                    workspaceToUse = adminSlice.workspaces.data.filter(
                      (r) => r.id === items[0].key
                    )[0];
                  }

                  updateColumn({
                    workspace: workspaceToUse,
                    resource: null,
                  });
                } else {
                }
              }}
              styles={{
                text: {
                  // Change the border-color to the same used on a textfield with an error.
                  // borderColor:
                  //     touched.selectedGroups && errors.selectedGroups && !workspacesReadonly
                  //         ? "rgb(164, 38, 44)"
                  //         : undefined,
                },
                input: {
                  height: workspacesReadonly ? 0 : undefined,
                },
              }}
              onResolveSuggestions={(filter, items) =>
                filterSuggestedTags(
                  adminSlice.workspaces.tags,
                  filter,
                  items as ITag[]
                )
              }
              onEmptyResolveSuggestions={(selectedItems) =>
                adminSlice.workspaces.tags.filter(
                  (tag) => !listContainsTagList(tag, selectedItems)
                )
              }
              inputProps={{
                placeholder: workspacesReadonly
                  ? undefined
                  : intl.formatMessage({
                      id: "portalPage.edit.embed.workspace.placeholder",
                    }),
                readOnly: workspacesReadonly,
              }}
              pickerCalloutProps={{
                hidden: workspacesReadonly,
              }}
              pickerSuggestionsProps={{
                // suggestionsHeaderText: loc.GeneralTagPickerSuggestionsHeader,
                noResultsFoundText: intl.formatMessage({
                  id: "general.tagpicker.noTagsFound",
                }),
              }}
            />
            {/* <TextFieldErrorMessage
                            errorMessage={touched.selectedGroups && errors.selectedGroups ? errors.selectedGroups.toString() : undefined}
                        /> */}

            <Label styles={{ root: { lineHeight: "normal" } }}>
              {column.resourceType === PortalPagePowerBiType.Report ? (
                <FormattedMessage id="portalPage.edit.embed.report.label" />
              ) : (
                <FormattedMessage id="portalPage.edit.embed.dashboard.label" />
              )}
            </Label>
            <TagPicker
              selectedItems={
                hasValue(column.resource)
                  ? [
                      {
                        key: column.resource.id,
                        name: resourceName,
                      } as ITag,
                    ]
                  : []
              }
              disabled={
                adminSlice.reportsForWorkspace.fetchStatus ===
                  SlicePieceStatus.IsFetching ||
                adminSlice.dashboardsForWorkspace.fetchStatus ===
                  SlicePieceStatus.IsFetching ||
                !hasValue(column.workspace)
              }
              onChange={(items) => {
                let resourceToUse: IPowerBiReport | IPowerBiDashboard | null =
                  null;
                if (items !== undefined && items.length > 0) {
                  resourceToUse =
                    column.resourceType === PortalPagePowerBiType.Report
                      ? adminSlice.reportsForWorkspace.data.filter(
                          (r) => r.id === items[0].key
                        )[0]
                      : adminSlice.dashboardsForWorkspace.data.filter(
                          (r) => r.id === items[0].key
                        )[0];
                }
                updateColumn({
                  resource: resourceToUse,
                });
              }}
              // onBlur={() => setFieldTouched("selectedResource")}
              styles={{
                text: {
                  // Change the border-color to the same used on a textfield with an error.
                  // borderColor: touched.selectedResource && errors.selectedResource ? "rgb(164, 38, 44)" : undefined, TODO
                },
                input: {
                  height: resourcesReadonly ? 0 : undefined,
                },
              }}
              onResolveSuggestions={(filter, items) =>
                filterSuggestedTags(resourceTags, filter, items as ITag[])
              }
              onEmptyResolveSuggestions={(selectedItems) =>
                resourceTags.filter(
                  (tag) => !listContainsTagList(tag, selectedItems)
                )
              }
              inputProps={{
                placeholder: resourcesReadonly
                  ? ""
                  : column.resourceType === PortalPagePowerBiType.Report
                  ? intl.formatMessage({
                      id: "portalPage.edit.embed.report.placeholder",
                    })
                  : intl.formatMessage({
                      id: "portalPage.edit.embed.dashboard.placeholder",
                    }),
                readOnly: resourcesReadonly,
              }}
              pickerCalloutProps={{
                hidden: resourcesReadonly,
              }}
              pickerSuggestionsProps={{
                // suggestionsHeaderText: loc.GeneralTagPickerSuggestionsHeader,
                noResultsFoundText: intl.formatMessage({
                  id: "general.tagpicker.noTagsFound",
                }),
              }}
            />
            {/* <TextFieldErrorMessage
                            errorMessage={
                                touched.selectedResource && errors.selectedResource ? errors.selectedResource.toString() : undefined
                            }
                        /> */}

            <DialogFooter>
              <PrimaryButton onClick={() => setPanelIsOpenState(!panelIsOpen)}>
                <FormattedMessage id="general.done" />
              </PrimaryButton>
              <DefaultButton onClick={() => setPanelIsOpenState(!panelIsOpen)}>
                <FormattedMessage id="general.close" />
              </DefaultButton>
            </DialogFooter>
          </Dialog>
        </div>
      </CardContent>
    </Card>
  );
};
