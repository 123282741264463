import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { msalGraphFetch, msalOneFetch } from "../../MSALConfig";
import { UserActionTypes, UserState } from "./types";

export const fetchUserPhotoUrl = async (userObjectId: string) => {
	try {
		const response: any = await msalGraphFetch(
			`https://graph.microsoft.com/v1.0/users/${userObjectId}/photo/$value`,
			{
				method: "GET",
				responseType: "blob",
			}
		);

		const blob = await response.blob();
		const blobUrl = window.URL.createObjectURL(blob);

		return blobUrl;
	} catch (error) {
		throw error;
	}
};

export const fetchCurrentUser: ActionCreator<ThunkAction<Promise<any>, UserState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: UserActionTypes.FETCH_CURRENT_USER_STARTED,
		});

		try {
			const response: Response = await msalOneFetch(`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/me`, {});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch({
						type: UserActionTypes.FETCH_CURRENT_USER_FAILURE,
					});
					return;
				}

				const result = await response.json();
				dispatch({
					type: UserActionTypes.FETCH_CURRENT_USER_SUCCESS,
					payload: result,
				});
			}
		} catch (e) {
			dispatch({
				type: UserActionTypes.FETCH_CURRENT_USER_FAILURE,
			});
		}
	};
};

export const fetchCurrentGraphUser: ActionCreator<ThunkAction<Promise<any>, UserState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: UserActionTypes.FETCH_CURRENT_USER_STARTED,
		});

		try {
			const response: Response = await msalGraphFetch(`https://graph.microsoft.com/v1.0/me`, {});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch({
						type: UserActionTypes.FETCH_CURRENT_GRAPH_USER_FAILURE,
					});
					return;
				}

				const result = await response.json();
				dispatch({
					type: UserActionTypes.FETCH_CURRENT_GRAPH_USER_SUCCESS,
					payload: result,
				});
			}
		} catch (e) {
			dispatch({
				type: UserActionTypes.FETCH_CURRENT_GRAPH_USER_FAILURE,
			});
		}
	};
};
