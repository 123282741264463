import { ApplicationContextTypes, ApplicationContextState } from "./types";
import { ISetting, SlicePieceStatus } from "@one/core";
import { TenantSubscriptionStatus } from "../../models/enums/TenantSubscriptionStatus";
import { OneThemes } from "../../styles/OneThemeProvider";

const initialState: ApplicationContextState = {
	settings: {
		data: {
			applicationSettings: [],
			tenantSettings: [],
			userSettings: [],
		},
		fetchStatus: SlicePieceStatus.None,
		updateStatus: SlicePieceStatus.None,
	},
	tenantSubscription: {
		data: TenantSubscriptionStatus.None,
		fetchStatus: SlicePieceStatus.IsFetching,
	},
	isOnMobile: false,
	
	theme: OneThemes.Default,
	displayName: null,
	imageUrl: null,
};

export function applicationContextReducer(
	state = initialState,
	action: { type: ApplicationContextTypes; payload: any }
): ApplicationContextState {
	switch (action.type) {
		// ---===[ Settings ]===---
		case ApplicationContextTypes.FETCH_SETTINGS_STARTED:
			return { ...state, settings: { ...state.settings, fetchStatus: SlicePieceStatus.IsFetching } };
		case ApplicationContextTypes.FETCH_SETTINGS_SUCCESS:
			let themeSetting = (action.payload.settings.applicationSettings as ISetting[]).filter((s) => {
				return s.key === "OneForReporting_theme";
			})[0];
			let displayNameSetting = (action.payload.settings.applicationSettings as ISetting[]).filter((s) => {
				return s.key === "OneForReporting_displayName";
			})[0];
			let imageUrlSetting = (action.payload.settings.applicationSettings as ISetting[]).filter((s) => {
				return s.key === "OneForReporting_imageUrl";
			})[0];

			return {
				...state,
				settings: {
					...state.settings,
					data: {
						applicationSettings: action.payload.settings.applicationSettings,
						tenantSettings: action.payload.settings.tenantSettings,
						userSettings: action.payload.settings.userSettings,
					},
					fetchStatus: SlicePieceStatus.Success,
				},
				theme: themeSetting !== undefined ? parseInt(themeSetting.value) as OneThemes : state.theme,
				displayName: displayNameSetting !== undefined ? displayNameSetting.value : state.displayName,
				imageUrl: imageUrlSetting !== undefined ? imageUrlSetting.value : state.imageUrl,
			};

		case ApplicationContextTypes.FETCH_SETTINGS_FAILURE:
			return { ...state, settings: { ...state.settings, fetchStatus: SlicePieceStatus.Error } };

		// Update ApplicationSettings
		case ApplicationContextTypes.UPDATE_APPLICATION_SETTINGS_STARTED:
			return { ...state, settings: { ...state.settings, updateStatus: SlicePieceStatus.IsFetching } };
		case ApplicationContextTypes.UPDATE_APPLICATION_SETTINGS_SUCCESS:
			themeSetting = (action.payload as ISetting[]).filter((s) => {
				return s.key === "OneForReporting_theme";
			})[0];
			return {
				...state,
				settings: {
					...state.settings,
					data: {
						...state.settings.data,
						applicationSettings: action.payload,
					},
					updateStatus: SlicePieceStatus.Success,
				},
				theme: parseInt(themeSetting.value) as OneThemes,
			};
		case ApplicationContextTypes.UPDATE_APPLICATION_SETTINGS_FAILED:
			return { ...state, settings: { ...state.settings, updateStatus: SlicePieceStatus.Error } };

		// ---===[ TenantSubscription ]===---
		case ApplicationContextTypes.CHECK_TENANT_SUBSCRIPTION_STARTED:
			return {
				...state,
				tenantSubscription: { ...state.tenantSubscription, fetchStatus: SlicePieceStatus.IsFetching },
			};
		case ApplicationContextTypes.CHECK_TENANT_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				tenantSubscription: {
					...state.tenantSubscription,
					data: action.payload,
					fetchStatus: SlicePieceStatus.Success,
				},
			};
		case ApplicationContextTypes.CHECK_TENANT_SUBSCRIPTION_FAILED:
			return {
				...state,
				tenantSubscription: { ...state.tenantSubscription, fetchStatus: SlicePieceStatus.Error },
			};

		// ---===[ Generic ]===---
		case ApplicationContextTypes.SET_IS_ON_MOBILE:
			return { ...state, isOnMobile: action.payload };
		case ApplicationContextTypes.SET_THEME:
			return { ...state, theme: action.payload };
		case ApplicationContextTypes.SET_DISPLAY_NAME:
			return { ...state, displayName: action.payload };
		case ApplicationContextTypes.SET_IMAGE_URL:
			return { ...state, imageUrl: action.payload };
		
		case ApplicationContextTypes.RESET_APPLICATION_SETTINGS_UPDATE_STATUS:
			return {
				...state,
				settings: {
					...state.settings,
					updateStatus: SlicePieceStatus.None,
				},
			};

		default:
			return state;
	}
}
