import { MessageBar, MessageBarType } from "@fluentui/react";
import { hasValue, IPortalPageColumnPowerBi, IPortalPageColumnText, IPortalPageContent, PortalPageColumnType } from "@one/core";
import { Card } from "@one/web";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { PowerBiEmbedColumn, TextColumn } from "./portalPageColumns";

export interface IPortalPageContentProps {
    content: IPortalPageContent | undefined;
}

export const PortalPageContent = (props: IPortalPageContentProps) => {
    const { content } = props;

    if (content === undefined || content === null || content.rows === undefined) {
        return (
            <div className="portal-page-row">
                <Card className="portal-page-column" style={{ width: "100%" }}>
                    <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                        <FormattedMessage id="portalPage.noContent" />
                    </MessageBar>
                </Card>
            </div>
        );
    }

    return (
        <>
            {content.rows.map((row, i) => {
                const columns = row.columns.filter((c) => c !== null);
                if (columns.length === 0) {
                    return null;
                }

                const columnsContainPowerBi = columns.filter((c) => c.type === PortalPageColumnType.PowerBi).length > 0;

                // Can be removed when the content is managed more dynamically.
                const shouldHideRow =
                    columns.filter((c) => c.type === PortalPageColumnType.Text && !hasValue((c as IPortalPageColumnText).text)).length > 0;

                if (shouldHideRow) {
                    return null;
                }

                return (
                    <div className="portal-page-row" style={{ height: columnsContainPowerBi ? "100%" : undefined }} key={i + "a"}>
                        {columns.map((column, n) => {
                            let columnUi: JSX.Element | null = null;
                            switch (column.type) {
                                case PortalPageColumnType.Text:
                                    columnUi = <TextColumn column={column as IPortalPageColumnText} />;
                                    break;

                                case PortalPageColumnType.PowerBi:
                                    columnUi = <PowerBiEmbedColumn column={column as IPortalPageColumnPowerBi} />;
                                    break;
                            }

                            return <React.Fragment key={n}>{columnUi}</React.Fragment>;
                        })}
                    </div>
                );
            })}
        </>
    );
};
