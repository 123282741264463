import { CommandBar, useTheme } from '@fluentui/react';
import { IPortalPageColumnText, IPortalPageContent, IPortalPageRow } from '@one/core';
import { Card, CardContent } from '@one/web';
import { FormikProps } from 'formik';
import * as React from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export interface IPowerBiEmbedProps {
    formikProps: FormikProps<IPortalPageContent>;
    column: IPortalPageColumnText;
    rowIndex: number;
}

export const TextColumnEdit = (props: IPowerBiEmbedProps) => {
    const { values, isSubmitting, setFieldValue } = props.formikProps;
    const theme = useTheme();

    const removeColumn = () => {
        const newRows = [...values.rows].filter((_row, index) => index !== props.rowIndex);
        setFieldValue('rows', newRows);
    };

    const updateColumnIndex = (newIndex: number) => {
        let newRows = [...values.rows].map((row, index) => {
            return {
                columns: [...row.columns].map((column) => {
                    return {
                        ...column,
                    };
                }),
            } as IPortalPageRow;
        });

        newRows[newIndex].columns[0] = { ...props.column };
        newRows[props.rowIndex].columns[0] = {
            ...values.rows[newIndex].columns[0],
        };

        setFieldValue('rows', newRows);
    };

    const updateColumn = (newColumn: any) => {
        const newRows = [...values.rows].map((row, index) => {
            if (index === props.rowIndex) {
                return {
                    columns: [...row.columns].map((column) => {
                        return {
                            ...column,
                            ...newColumn,
                        };
                    }),
                } as IPortalPageRow;
            } else {
                return row;
            }
        });

        setFieldValue('rows', newRows);
    };

    return (
        <Card className="portal-page-column" style={{ width: '100%' }}>
            <CommandBar
                items={[]}
                className={'webpart-edit-command-bar'}
                styles={{
                    root: {
                        height: '40px',
                        background: theme.palette.neutralLighterAlt,
                        borderBottom: '1px solid',
                        borderColor: theme.palette.neutralLighter,
                        button: {
                            background: theme.palette.neutralLighterAlt,
                        },
                        cursor: 'default',
                    },
                }}
                farItems={[
                    {
                        key: 'moveUp',
                        buttonStyles: {
                            icon: {
                                color: theme.palette.black,
                            },
                        },
                        disabled: props.rowIndex === 0,
                        iconProps: {
                            iconName: 'Up',
                        },
                        onClick: () => updateColumnIndex(props.rowIndex - 1),
                    },
                    {
                        key: 'moveDown',
                        buttonStyles: {
                            icon: {
                                color: theme.palette.black,
                            },
                        },
                        disabled: props.rowIndex === props.formikProps.values.rows.length - 1,
                        iconProps: {
                            iconName: 'Down',
                        },
                        onClick: () => updateColumnIndex(props.rowIndex + 1),
                    },
                    {
                        key: 'remove',
                        buttonStyles: {
                            icon: {
                                color: theme.palette.black,
                            },
                        },
                        iconProps: {
                            iconName: 'Trash',
                        },
                        onClick: () => removeColumn(),
                    },
                ]}
            />
            <CardContent>
                <ReactQuill
                    id="description"
                    theme="snow"
                    className="one-quill-wrapper"
                    value={props.column.text || ''}
                    modules={{
                        toolbar: [
                            [{ header: [1, 2, 3, false] }],
                            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                            ['link'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                            [{ color: [] }],
                            [{ align: [] }],
                            ['clean'], // remove formatting button
                        ],
                    }}
                    onChange={(content, _delta, _source, _editor) => {
                        // This if statement fixes a bug when switching place with another text editor. For some reason the onChange function gets called 3 times.
                        if (_source === 'user') {
                            const valueIsEmpty = content.replace(/<(.|\n)*?>/g, '').trim().length === 0;

                            updateColumn({
                                text: valueIsEmpty ? '' : content,
                            });
                        }
                    }}
                    readOnly={isSubmitting}
                />
            </CardContent>
        </Card>
    );
};
