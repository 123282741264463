import * as React from "react";
import {
	ApplicationActionBar,
	ApplicationHeader,
	ApplicationHeaderSection,
	NavBar,
	Skeleton,
	PageContainer,
	PageContent,
	ApplicationHeaderButton,
	ApplicationHeaderTitle,
	ApplicationHeaderLogo,
} from "@one/web";
import oneLogo from "../assets/images/One.png";
import { useState } from "react";
import { Navigation } from "./layout/navigation/Navigation";
import { Route, Switch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { hasAccess, hasValue, PermissionCodes, SlicePieceStatus } from "@one/core";
import { Spinner } from "@fluentui/react";

import { IApplicationState } from "../models/interfaces/IApplicationState";
import { SettingsPanel } from "./layout/settingsPanel/SettingsPanel";
import { AccountPanel } from "./layout/accountPanel/AccountPanel";
import { PageNotFound, PortalPage } from "./routes";
import { Welcome } from "./routes/welcome/Welcome";
import { fetchSettings, resetApplicationSettingsUpdateStatus } from "../store/application/actions";

export const ApplicationLayout = () => {
	const { pagesSlice, applicationContextSlice, userSlice } = useSelector((state: IApplicationState) => state);
	const location = useSelector((state: IApplicationState) => state.router.location);
	const dispatch = useDispatch();
	const history = useHistory();

	const [accountPanelIsOpen, setAccountPanelIsOpen] = useState(false);
	const [settingsPanelIsOpen, setSettingsPanelIsOpen] = useState(false);

	const [mobilePanelIsOpen, setMobilePanelIsOpen] = useState<boolean>(false);
	const [routesUi, setRoutesUi] = useState<JSX.Element[] | JSX.Element | null>(null);

	const renderRoutes = () => {
		const result = pagesSlice.pagesFlatList.map((page) => {
			return <Route key={page.id} exact path={page.path} component={() => <PortalPage portalPage={page} />} />;
		});

		// Add a PageNotFound route
		result.push(<Route key="welcome" exact path="/" component={() => <Welcome />} />);
		result.push(<Route key="notFound" component={() => <PageNotFound />} />);
		return result;
	};

	// When
	React.useEffect(() => {
		if (applicationContextSlice.settings.updateStatus === SlicePieceStatus.Success) {
			setSettingsPanelIsOpen(false);
			dispatch(fetchSettings());
			dispatch(resetApplicationSettingsUpdateStatus());
		}
	}, [applicationContextSlice.settings.updateStatus]);

	React.useEffect(() => {
		setRoutesUi(renderRoutes());

		// If there are any pages and the path is at the root, navigate to the first page.
		if (pagesSlice.pagesFlatList.length > 0 && location.pathname === "/") {
			history.push(pagesSlice.pagesFlatList[0].path);
		}
	}, [pagesSlice.pagesFlatList]);

	React.useEffect(() => {
		let splittedPath = location.pathname.split("/");
		let encodedPathPieces = splittedPath.map((piece) => encodeURI(piece));

		history.push(encodedPathPieces.join("/"));
	}, [routesUi]);

	// Navigate to the right path when the currentPage changes, for example after updating the page name.
	React.useEffect(() => {
		if (pagesSlice.currentPage.page !== null && pagesSlice.currentPage.page.path !== location.pathname) {
			history.push(pagesSlice.currentPage.page.path);
		}
	}, [pagesSlice.currentPage]);

	return (
		<>
			<Skeleton>
				<NavBar>
					<ApplicationHeader>
						<ApplicationHeaderSection>
							{applicationContextSlice.isOnMobile ? (
								<ApplicationHeaderButton
									iconName="NumberedListText"
									onClick={() => setMobilePanelIsOpen(!mobilePanelIsOpen)}
								/>
							) : (
								<ApplicationHeaderButton iconName="WaffleOffice365" href="https://portal.office.com" target="_blank" />
							)}
							<ApplicationHeaderTitle
								title={
									hasValue(applicationContextSlice.displayName)
										? (applicationContextSlice.displayName as string)
										: "One for Reporting"
								}
							/>
						</ApplicationHeaderSection>
						{!applicationContextSlice.isOnMobile && (
							<ApplicationHeaderSection>
								<ApplicationHeaderLogo
									img={
										hasValue(applicationContextSlice.imageUrl) ? (applicationContextSlice.imageUrl as string) : oneLogo
									}
									imgAlt="Logo"
								/>
							</ApplicationHeaderSection>
						)}
						<ApplicationHeaderSection>
							{hasAccess(userSlice.currentUser.data, [PermissionCodes.ManageReportingPortalSettings]) && (
								<ApplicationHeaderButton iconName="Settings" onClick={() => setSettingsPanelIsOpen(true)} />
							)}
							<ApplicationHeaderButton iconName="People" onClick={() => setAccountPanelIsOpen(true)} />
						</ApplicationHeaderSection>
					</ApplicationHeader>
					<ApplicationActionBar includeNavBarPadding farItems={[]} />
				</NavBar>
				<PageContainer>
					<Navigation mobilePanelIsOpen={mobilePanelIsOpen} setMobilePanelIsOpen={setMobilePanelIsOpen} />

					<PageContent>
						{pagesSlice.pages.fetchStatus === SlicePieceStatus.IsFetching ? (
							<div className="app-centered">
								<div className="loaderWrapper">
									<Spinner />
								</div>
							</div>
						) : (
							<Switch location={location}>{routesUi}</Switch>
						)}
					</PageContent>
				</PageContainer>
			</Skeleton>

			{hasAccess(userSlice.currentUser.data, [PermissionCodes.ManageReportingPortalPages]) && (
				<AccountPanel isOpen={accountPanelIsOpen} onDismiss={() => setAccountPanelIsOpen(false)} />
			)}
			<SettingsPanel isOpen={settingsPanelIsOpen} onDismiss={() => setSettingsPanelIsOpen(false)} />
		</>
	);
};
