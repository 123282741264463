import { User } from "@microsoft/microsoft-graph-types";
import { IOneUser, ISlicePiece } from "@one/core";

export enum UserActionTypes {
	FETCH_CURRENT_USER_STARTED = "FETCH_CURRENT_USER_STARTED",
	FETCH_CURRENT_USER_SUCCESS = "FETCH_CURRENT_USER_SUCCESS",
	FETCH_CURRENT_USER_FAILURE = "FETCH_CURRENT_USER_FAILURE",

	FETCH_CURRENT_GRAPH_USER_STARTED = "FETCH_CURRENT_GRAPH_USER_STARTED",
	FETCH_CURRENT_GRAPH_USER_SUCCESS = "FETCH_CURRENT_GRAPH_USER_SUCCESS",
	FETCH_CURRENT_GRAPH_USER_FAILURE = "FETCH_CURRENT_GRAPH_USER_FAILURE",
}

export type UserState = Readonly<{
	currentUser: ISlicePiece<IOneUser>;
	currentGraphUser: ISlicePiece<User | null>;
}>;
