import { useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import { OneThemeProvider, OneThemes } from "../styles/OneThemeProvider";

export const UnAuthenticated = () => {
	const { instance, accounts } = useMsal();

	useEffect(() => {
		if (accounts === null || accounts === undefined || accounts.length === 0) {
			instance.loginRedirect();
			return;
		}
	}, []);

	return (
		<OneThemeProvider theme={OneThemes.Default}>
			<div>UITGELOGD</div>
		</OneThemeProvider>
	);
};
