import { hasValue, IPortalPage, SlicePieceStatus } from "@one/core";
import { CurrentPageActionToExecute } from "../../models/enums/CurrentPageActionToExecute";
import { PagesActionTypes, PagesState } from "./types";

const initialState: PagesState = {
	pages: {
		data: [],
		createStatus: SlicePieceStatus.None,
		deleteStatus: SlicePieceStatus.None,
		fetchStatus: SlicePieceStatus.IsFetching,
		updateStatus: SlicePieceStatus.None,
	},
	pagesFlatList: [] as IPortalPage[],

	currentPage: {
		page: null,
		isInEditMode: false,
		actionToExecute: CurrentPageActionToExecute.None,
		updateContentStatus: SlicePieceStatus.None,
	},
};

export function pagesReducer(state = initialState, action: { type: PagesActionTypes; payload: any }): PagesState {
	const mapPortalPageResponse = (page: IPortalPage) => {
		let newPage: IPortalPage = { ...page };

		if (hasValue(page.content) && typeof page.content === "string") {
			newPage.content = JSON.parse(page.content as unknown as string);
		}

		if (page.childPages && page.childPages.length > 0) {
			newPage.childPages = mapPortalPagesResponse(page.childPages);
		}

		return newPage;
	};

	const mapPortalPagesResponse = (input: IPortalPage[]) => {
		return input.map((page) => {
			return mapPortalPageResponse(page);
		});
	};

	/**
	 * Creates a flat list of re protal pages
	 * @param input 
	 * @param result 
	 * @returns 
	 */
	const flattenPages = (input: IPortalPage[], result: IPortalPage[] = []) => {
		for (let i = 0; i < input.length; i++) {
			let portalPage = input[i];
			result.push(mapPortalPageResponse(portalPage));
			if (portalPage.childPages && portalPage.childPages.length > 0) {
				result = flattenPages(portalPage.childPages, result);
			}
		}

		return result;
	};

	/**
	 * Recursively adds a new page to the given parent. The new page is always
	 * added to the end of the child array.
	 * @param input
	 * @param pageToAdd
	 * @param parentId
	 * @returns
	 */
	const addPageResursive = (input: IPortalPage[], pageToAdd: IPortalPage, parentId: number | undefined) => {
		const result: IPortalPage[] = [];

		for (let i = 0; i < input.length; i++) {
			let portalPage = input[i];

			if (parentId !== undefined && portalPage.id === parentId) {
				portalPage.childPages.push(pageToAdd);
			} else {
				if (portalPage.childPages && portalPage.childPages.length > 0) {
					portalPage.childPages = addPageResursive(portalPage.childPages, pageToAdd, parentId);
				}
			}
			result.push(portalPage);
		}

		return result;
	};

	/**
	 * Recursively updates a page.
	 * @param input
	 * @param pageToAdd
	 * @param parentId
	 * @returns
	 */
	const updatePageResursive = (input: IPortalPage[], pageToUpdate: IPortalPage) => {
		const result: IPortalPage[] = [];

		for (let i = 0; i < input.length; i++) {
			let portalPage = input[i];
			
			if (portalPage.id === pageToUpdate.id) {
				result.push(pageToUpdate);
			} else {
				if (portalPage.childPages && portalPage.childPages.length > 0) {
					portalPage.childPages = updatePageResursive(portalPage.childPages, pageToUpdate);

					//sort the pages based on the sequence.
					portalPage.childPages = portalPage.childPages.sort((a, b) => {

						//Add one to the sequence if the sequence equals the updated page
						//and the looped current page not equals the page to update.
						if(a.sequence >= pageToUpdate.sequence && a.id !== pageToUpdate.id) {
							a.sequence = a.sequence+1;
						}

						return a.sequence-b.sequence
					});
				}
				result.push(portalPage);
			}
		}

		return result;
	};

	/**
	 * Recursively removed the given page from the array. This will also
	 * removed the children.
	 * @param input
	 * @param pageToRemove
	 * @returns
	 */
	const removePageResursive = (input: IPortalPage[], pageToRemove: IPortalPage) => {
		const result: IPortalPage[] = [];

		for (let i = 0; i < input.length; i++) {
			let portalPage = input[i];

			if (portalPage.id !== pageToRemove.id) {
				if (portalPage.childPages && portalPage.childPages.length > 0) {
					portalPage.childPages = removePageResursive(portalPage.childPages, pageToRemove);
				}

				result.push(portalPage);
			}
		}

		return result;
	};

	switch (action.type) {
		case PagesActionTypes.FETCH_PAGES_STARTED:
			return {
				...state,
				pages: {
					...state.pages,
					fetchStatus: SlicePieceStatus.IsFetching,
				},
			};
		case PagesActionTypes.FETCH_PAGES_SUCCESS:
			return {
				...state,
				pagesFlatList: flattenPages(action.payload),
				pages: {
					...state.pages,
					data: mapPortalPagesResponse(action.payload),
					fetchStatus: SlicePieceStatus.Success,
				},
			};
		case PagesActionTypes.FETCH_PAGES_FAILURE:
			return {
				...state,
				pages: {
					...state.pages,
					fetchStatus: SlicePieceStatus.Error,
				},
			};

		/* CREATE */
		case PagesActionTypes.CREATE_PAGE_STARTED:
			return {
				...state,
				pages: {
					...state.pages,
					createStatus: SlicePieceStatus.IsFetching,
				},
			};
		case PagesActionTypes.CREATE_PAGE_SUCCESS:
			let addedPage = mapPortalPageResponse(action.payload);
			let newPages = [...state.pages.data];
		
			//root-level
			if (addedPage.parentPageId === null) {
				newPages.push(addedPage);
			}
			//non-root-level
			else {
				newPages = addPageResursive(newPages, addedPage, addedPage.parentPageId);
			}

			return {
				...state,
				currentPage: {
					...state.currentPage,
					page: addedPage,
					isInEditMode: false,
					actionToExecute: CurrentPageActionToExecute.None,
				},
				pages: {
					...state.pages,
					data: newPages,
					createStatus: SlicePieceStatus.Success,
				},
				pagesFlatList: flattenPages(newPages),
			};
		case PagesActionTypes.CREATE_PAGE_FAILURE:
			return {
				...state,
				pages: {
					...state.pages,
					createStatus: SlicePieceStatus.Error,
				},
			};

		/* UPDATE PAGE */
		case PagesActionTypes.UPDATE_PAGE_STARTED:
			return {
				...state,
				pages: {
					...state.pages,
					updateStatus: SlicePieceStatus.IsFetching,
				},
			};

		case PagesActionTypes.UPDATE_PAGE_SUCCESS:
			let updatedObject = mapPortalPageResponse(action.payload);
			let updatedPages = updatePageResursive([...state.pages.data], updatedObject)

			return {
				...state,
				currentPage: {
					...state.currentPage,
					page: updatedObject,
					isInEditMode: false,
					actionToExecute: CurrentPageActionToExecute.None,
				},
				pages: {
					...state.pages,
					data: updatedPages,
					updateStatus: SlicePieceStatus.Success,
				},
				pagesFlatList: flattenPages(updatedPages),
			};

		case PagesActionTypes.UPDATE_PAGE_FAILURE:
			return {
				...state,
				pages: {
					...state.pages,
					updateStatus: SlicePieceStatus.Error,
				},
			};

		/* UPDATE STATUS */
		case PagesActionTypes.UPDATE_PAGE_STATUS_STARTED:
			return {
				...state,
				pages: {
					...state.pages,
					deleteStatus: SlicePieceStatus.IsFetching,
				},
			};

		case PagesActionTypes.UPDATE_PAGE_STATUS_SUCCESS:
			let pageToRemove = action.payload as IPortalPage;
			let statusUpdatedPages = removePageResursive([...state.pages.data], pageToRemove);

			return {
				...state,
				pages: {
					...state.pages,
					data: statusUpdatedPages,
					deleteStatus: SlicePieceStatus.Success,
				},
				pagesFlatList: flattenPages(statusUpdatedPages),
			};

		case PagesActionTypes.UPDATE_PAGE_STATUS_FAILURE:
			return {
				...state,
				pages: {
					...state.pages,
					deleteStatus: SlicePieceStatus.Error,
				},
			};

		/* UPDATE CONTENT */
		case PagesActionTypes.UPDATE_PAGE_CONTENT_STARTED:
			return {
				...state,
				currentPage: {
					...state.currentPage,
					updateContentStatus: SlicePieceStatus.IsFetching,
				},
			};

		case PagesActionTypes.UPDATE_PAGE_CONTENT_SUCCESS:
			const updatedContentPage = mapPortalPageResponse(action.payload);
			let newPagesUpdatedContent = updatePageResursive([...state.pages.data], updatedContentPage);

			return {
				...state,
				currentPage: {
					...state.currentPage,
					page: updatedContentPage,
					isInEditMode: false,
					actionToExecute: CurrentPageActionToExecute.None,
					updateContentStatus: SlicePieceStatus.Success,
				},
				pages: {
					...state.pages,
					data: newPagesUpdatedContent,
				},
				pagesFlatList: flattenPages(newPagesUpdatedContent),
			};

		case PagesActionTypes.UPDATE_PAGE_CONTENT_FAILURE:
			return {
				...state,
				currentPage: {
					...state.currentPage,
					updateContentStatus: SlicePieceStatus.Error,
				},
			};

		// Current Page
		case PagesActionTypes.SET_CURRENT_PAGE:
			return {
				...state,
				currentPage: {
					...state.currentPage,
					page: action.payload,
				},
			};
		case PagesActionTypes.SET_EDIT_MODE:
			return {
				...state,
				currentPage: {
					...state.currentPage,
					isInEditMode: action.payload,
				},
			};
		case PagesActionTypes.SET_ACTION_TO_EXECUTE:
			return {
				...state,
				currentPage: {
					...state.currentPage,
					actionToExecute: action.payload,
				},
			};

		// RESET
		case PagesActionTypes.RESET_PAGES_STATUSSES:
			return {
				...state,
				pages: {
					...state.pages,
					fetchStatus: state.pages.fetchStatus === undefined ? undefined : SlicePieceStatus.None,
					createStatus: state.pages.createStatus === undefined ? undefined : SlicePieceStatus.None,
					updateStatus: state.pages.updateStatus === undefined ? undefined : SlicePieceStatus.None,
					deleteStatus: state.pages.deleteStatus === undefined ? undefined : SlicePieceStatus.None,
				},
			};


		/* UPDATE ORDER/D&D */
		case PagesActionTypes.UPDATE_PAGE_ORDER_STARTED:
			return {
				...state,
				currentPage: {
					...state.currentPage
				},
				pages: {
					...state.pages,
					updateStatus: SlicePieceStatus.IsFetching,
				}
			};

		case PagesActionTypes.UPDATE_PAGE_ORDER_SUCCESS:
			let updatedDraggedPages = action.payload.updatedPages as IPortalPage[];
			let newFlattenPages = flattenPages(updatedDraggedPages);
			let newCurrentPage = newFlattenPages.filter(p => p.id === action.payload.pageId)[0];

			return {
				...state,
				currentPage: {
					...state.currentPage,
					page: newCurrentPage,
					actionToExecute: CurrentPageActionToExecute.None
				},
				pagesFlatList: newFlattenPages,
				pages: {
					...state.pages,
					updateStatus: SlicePieceStatus.Success,
					data: updatedDraggedPages,
				}
			};

		case PagesActionTypes.UPDATE_PAGE_ORDER_FAILURE:
			return {
				...state,
				currentPage: {
					...state.currentPage,
					updateContentStatus: SlicePieceStatus.Error,
				},
				pages: {
					...state.pages,
					updateStatus: SlicePieceStatus.Error,
				}
			};


		default:
			return state;
	}
}
