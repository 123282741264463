import { Group } from "@microsoft/microsoft-graph-types";
import { IPowerBiDashboard, IPowerBiReport, IPowerBiWorkspace, ISlicePiece } from "@one/core";

export enum AdminActionTypes {
	FETCH_SECURITY_GROUPS_STARTED = "FETCH_SECURITY_GROUPS_STARTED",
	FETCH_SECURITY_GROUPS_SUCCESS = "FETCH_SECURITY_GROUPS_SUCCESS",
	FETCH_SECURITY_GROUPS_FAILURE = "FETCH_SECURITY_GROUPS_FAILURE",

	FETCH_WORKSPACES_STARTED = "FETCH_WORKSPACES_STARTED",
	FETCH_WORKSPACES_SUCCESS = "FETCH_WORKSPACES_SUCCESS",
	FETCH_WORKSPACES_FAILURE = "FETCH_WORKSPACES_FAILURE",

	FETCH_REPORTS_FOR_WORKSPACE_STARTED = "FETCH_REPORTS_FOR_WORKSPACE_STARTED",
	FETCH_REPORTS_FOR_WORKSPACE_SUCCESS = "FETCH_REPORTS_FOR_WORKSPACE_SUCCESS",
	FETCH_REPORTS_FOR_WORKSPACE_FAILURE = "FETCH_REPORTS_FOR_WORKSPACE_FAILURE",

	FETCH_DASHBOARDS_FOR_WORKSPACE_STARTED = "FETCH_DASHBOARDS_FOR_WORKSPACE_STARTED",
	FETCH_DASHBOARDS_FOR_WORKSPACE_SUCCESS = "FETCH_DASHBOARDS_FOR_WORKSPACE_SUCCESS",
	FETCH_DASHBOARDS_FOR_WORKSPACE_FAILURE = "FETCH_DASHBOARDS_FOR_WORKSPACE_FAILURE",
}

export type AdminState = Readonly<{
	securityGroups: ISlicePiece<Group[]>;
	workspaces: ISlicePiece<IPowerBiWorkspace[]>;
	reportsForWorkspace: ISlicePiece<IPowerBiReport[]>;
	dashboardsForWorkspace: ISlicePiece<IPowerBiDashboard[]>;
}>;
