import { combineReducers, createStore, applyMiddleware } from "redux";
import ThunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory, History } from "history";

import { userReducer } from "./user/reducer";
import { connectRouter } from "connected-react-router";
import { IApplicationState } from "../models/interfaces/IApplicationState";
import { applicationContextReducer } from "./application/reducer";
import { adminReducer } from "./admin/reducer";
import { pagesReducer } from "./pages/reducer";

export const history = createBrowserHistory();

// This has to be before the 'combineReducers' function runs.
// Therefore this can not be placed in the indexApp.tsx
// if (window.location.pathname === "/") {
// 	history.push("/home");
// }

const rootReducer = (history: History<unknown>) =>
	combineReducers<IApplicationState>({
		router: connectRouter(history),
		userSlice: userReducer,
		applicationContextSlice: applicationContextReducer,
		adminSlice: adminReducer,
		pagesSlice: pagesReducer,
	});

export default function configureStore() {
	const middleware = [ThunkMiddleware];
	const middleWareEnhancer = applyMiddleware(...middleware);

	return createStore(rootReducer(history), composeWithDevTools(middleWareEnhancer));
}
