import { Configuration, PublicClientApplication } from "@azure/msal-browser";

export const msalConfig: Configuration = {
	auth: {
		clientId: process.env.REACT_APP_CLIENT_ID!,
		knownAuthorities: ["00000009-0000-0000-c000-000000000000"],
		authority: "https://login.microsoftonline.com/organizations",
		redirectUri: window.location.origin,
	},
	cache: {
		cacheLocation: "sessionStorage", // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const scopes = {
	oneScopes: [`${process.env.REACT_APP_ONE_API_RESOURCE_ID!}/.default`],
	graphScopes: ["User.Read"],
	powerBiScopes: ["https://analysis.windows.net/powerbi/api/.default"],
};

export const msalOneFetch = async (url: string, options: any) => {
	const account = msalInstance.getAllAccounts()[0];
	const authResult = await msalInstance.acquireTokenSilent({ scopes: scopes.oneScopes, account: account });

	let headers = new Headers();
	let bearer = "Bearer " + authResult.accessToken;
	headers.append("Authorization", bearer);
	headers.append("Content-type", "application/json");

	return fetch(url, {
		...options,
		headers: headers,
	});
};

export const msalGraphFetch = async (url: string, options: any) => {
	const account = msalInstance.getAllAccounts()[0];
	const authResult = await msalInstance.acquireTokenSilent({ scopes: scopes.graphScopes, account: account });

	let headers = new Headers();
	let bearer = "Bearer " + authResult.accessToken;
	headers.append("Authorization", bearer);

	return fetch(url, {
		...options,
		headers: headers,
	});
};

export const msalPowerBiFetch = async (url: string, options: any) => {
	const account = msalInstance.getAllAccounts()[0];
	const authResult = await msalInstance.acquireTokenSilent({ scopes: scopes.powerBiScopes, account: account });

	let headers = new Headers();
	let bearer = "Bearer " + authResult.accessToken;
	headers.append("Authorization", bearer);

	return fetch(url, {
		...options,
		headers: headers,
	});
};

export const getPowerBiAccessToken = async () => {
	const account = msalInstance.getAllAccounts()[0];
	return await msalInstance.acquireTokenSilent({ scopes: scopes.powerBiScopes, account: account });
};
