import * as React from "react";
const OneThemeDefault = React.lazy(() => import("./theme/OneThemeDefault"));
const OneThemeRed = React.lazy(() => import("./theme/OneThemeRed"));
const OneThemeGreen = React.lazy(() => import("./theme/OneThemeGreen"));
const OneThemeOrange = React.lazy(() => import("./theme/OneThemeOrange"));

export enum OneThemes {
	Default,
	Green,
	Orange,
	Red,
}

export interface IThemeProv {
	theme: OneThemes;
	children?: React.ReactNode;
}

export const OneThemeProvider = (props: IThemeProv) => {
	return (
		<>
			<React.Suspense fallback={React.Fragment}>
				{props.theme === OneThemes.Red && <OneThemeRed>{props.children}</OneThemeRed>}
				{props.theme === OneThemes.Green && <OneThemeGreen>{props.children}</OneThemeGreen>}
				{props.theme === OneThemes.Orange && <OneThemeOrange>{props.children}</OneThemeOrange>}
				{props.theme === OneThemes.Default && <OneThemeDefault>{props.children}</OneThemeDefault>}
			</React.Suspense>
		</>
	);
};
