export default {
	// --== General ==--
	"general.makeAChoice": "Selecteer een optie",
	"general.ok": "Oké",
	"general.yes": "Ja",
	"general.save": "Opslaan",
	"general.cancel": "Annuleren",
	"general.done": "Klaar",
	"general.close": "Sluiten",
	"general.add": "Toevoegen",
	"general.required": "Dit veld is verplicht.",
	"general.somethingWentWrong": "Er is iets misgegaan bij het opslaan.",
	"general.confirm": "Bevestigen",
	"general.welcome": "Welkom",
	"general.edit": "Bewerken",

	"general.tagpicker.noTagsFound": "Geen resultaten",

	// --== Welcome ==--
	"welcome.noPagesUser": "Er zijn nog geen pagina's aangemaakt, neem contact op met de administrator.",
	"welcome.noPagesAdmin": "Maak een pagina aan door onderin het navigatie menu op de knop te drukken.",

	// --== AccountPanel ==--
	"accountPanel.myAccount": "Mijn account",
	"accountPanel.myProfile": "Mijn profiel",
	"accountPanel.logout": "Uitloggen",

	// --== SettingsPanel ==--
	"settingsPanel.header": "Portaal instellingen",
	"settingsPanel.portal.header": "Portaal",
	"settingsPanel.portal.theme.label": "Thema",
	"settingsPanel.portal.imageUrl.label": "Logo",
	"settingsPanel.portal.imageUrl.placeholder": "Vul een link naar een afbeelding in.",
	"settingsPanel.portal.imageUrl.preview": "Voorbeeld",
	"settingsPanel.portal.imageUrl.notFound": "Afbeelding kan niet worden geladen.",
	"settingsPanel.portal.name.label": "Naam",
	"settingsPanel.portal.name.placeholder": "Bijv. One for Reporting",
	"settingsPanel.refreshNeeded":
		"De applicatie dient te verversen om deze wijzigingen door te voeren, openstaande wijzigingen bij pagina's zullen verloren gaan.",
	"settingsPanel.theme.option.default": "Blauw",
	"settingsPanel.theme.option.green": "Groen",
	"settingsPanel.theme.option.orange": "Oranje",
	"settingsPanel.theme.option.red": "Rood",

	"settingsPanel.save.error": "Kon de instellingen niet opslaan.",

	// --== Subscription ==--
	"subscription.inactive": "Uw abonnement is verlopen, neem contact op met uw beheerder",
	"subscription.none": "U heeft geen abonnement voor One.",
	"subscription.error": "Er is iets fout gegaan bij het ophalen van uw abonnement.",

	// --== oneNavigation ==--
	"oneNavigation.footer.add": "Nieuwe pagina toevoegen",
	"oneNavigation.action.add": "Pagina aanmaken",
	"oneNavigation.action.edit": "Pagina bewerken",
	"oneNavigation.action.delete": "Pagina verwijderen",
	"oneNavigation.action.delete.title": "Pagina verwijderen bevestigen",
	"oneNavigation.action.delete.subtext":
		"Na het verwijderen zal de pagina zal niet meer toegankelijk zijn. Weet je zeker dat je deze pagina wilt verwijderen?",
	"oneNavigation.action.delete.error": "Er is iets misgegaan tijdens het verwijderen van de pagina.",
	"oneNavigation.action.create.error": "Er is iets misgegaan tijdens het aanmaken van de pagina.",
	"oneNavigation.action.update.error": "Er is iets misgegaan tijdens het updaten van de pagina.",

	// --== AddModalPage ==--
	"addModalPage.dialog.title": "Pagina aanmaken",
	"addModalPage.dialog.subtext": "Maak een nieuwe pagina aan om een nieuw rapportoverzicht te tonen.",
	"addModalPage.dialog.input.pageName": "Pagina naam",
	"addModalPage.dialog.input.icon": "FluentUi Icon",
	"addModalPage.dialog.input.securityGroups": "Wie heeft er toegang tot de pagina?",
	"addModalPage.dialog.teachingBubble.headline": "Toegang tot de pagina",
	"addModalPage.dialog.teachingBubble.text":
		"Hiermee kun je bepalen wie de pagina mag zien en benaderen. Waneer je geen groepen aanvinkt heeft iedereen toegang.",


	// PortalPage
	"portalPage.noContent": "Deze pagina heeft geen inhoud, neem contact op met uw administrator.",
	"portalPage.powerbi.report.print.label": "Printen",
	"portalPage.powerbi.report.fullscreen.label": "Volledig scherm",

	// --== PortalPageEditMode ==--
	"portalPage.addWebpart.add": "Webpart toevoegen",
	"portalPage.addWebpart.powerbi.label": "PowerBi",
	"portalPage.addWebpart.powerbi.description": "Embed een PowerBi rapport of dashboard",
	"portalPage.addWebpart.text.label": "Tekst",
	"portalPage.addWebpart.text.description": "Een RichText editor",

	"portalPage.edit.configuration.required": "Configuratie vereist",

	"portalPage.edit.enterEditMode.button.label": "Bewerken",
	"portalPage.edit.discard.button.label": "Wijzigingen negeren",
	"portalPage.edit.description.label": "Omschrijving",
	"portalPage.edit.powerbi.label": "PowerBi",

	"portalPage.edit.embed.type.label": "Type",
	"portalPage.edit.embed.type.report": "Rapport",
	"portalPage.edit.embed.type.dashboard": "Dashboard",
	"portalPage.edit.embed.workspace.label.report": "Selecteer de workspace waarin het rapport zich bevindt",
	"portalPage.edit.embed.workspace.label.dashboard": "Selecteer de workspace waarin het dashboard zich bevindt",
	"portalPage.edit.embed.workspace.placeholder": "Selecteer of type de naam van de workspace",

	"portalPage.edit.embed.report.label": "Selecteer het rapport dat u wilt embedden",
	"portalPage.edit.embed.report.placeholder": "Selecteer of type de naam van het rapport",
	"portalPage.edit.embed.dashboard.label": "Selecteer het dashboard dat u wilt embedden",
	"portalPage.edit.embed.dashboard.placeholder": "Selecteer of type de naam van het dashboard",
};
