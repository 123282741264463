import { IPortalPageContent } from "@one/core";
import { FormikProps } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CurrentPageActionToExecute } from "../../../../models/enums/CurrentPageActionToExecute";
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { setActionToExecute } from "../../../../store/pages/actions";

/**
 * Since an UseEffect cannot be inside the formik component and we need access to the values this
 * controller is created.
 */
export const FormikPortalPageController = (
  props: FormikProps<IPortalPageContent>
) => {
  const dispatch = useDispatch();
  const { pagesSlice } = useSelector((state: IApplicationState) => state);

  React.useEffect(() => {
    // Trigger a submit when the submit button is pressed.
    // This is required since the submit button is positioned outside Formik.
    if (
      pagesSlice.currentPage.actionToExecute === CurrentPageActionToExecute.Save
    ) {
      if (!props.isSubmitting) {
        props.submitForm();
      } else {
        dispatch(setActionToExecute(CurrentPageActionToExecute.None));
      }
    }
  }, [props.isSubmitting, pagesSlice.currentPage.actionToExecute]);

  return <React.Fragment></React.Fragment>;
};
