import { IPortalPageColumnText } from "@one/core";
import { Card, CardContent } from "@one/web";
import * as React from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export interface IPortalPageColumnTextProps {
    column: IPortalPageColumnText;
}

export const TextColumn = (props: IPortalPageColumnTextProps) => {
    const { column } = props;

    return (
        <Card className="portal-page-column" style={{ width: `${column.width || 100}%` }}>
            <CardContent>
                <ReactQuill
                    theme="snow"
                    className="one-quill-wrapper-no-border"
                    readOnly={true}
                    value={column.text}
                    modules={{
                        toolbar: false,
                    }}
                />
            </CardContent>
        </Card>
    );
};
