import { DefaultButton } from "@fluentui/react";
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import { updatePortalPageContent } from "../../../../store/pages/actions";
import {
  IPortalPageColumnPowerBi,
  IPortalPageColumnText,
  IPortalPageContent,
  PortalPageColumnType,
  PortalPagePowerBiType,
} from "@one/core";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { FormikPortalPageController } from "./FormikPortalPageController";
import { PowerBiEmbedColumnEdit } from "./editColumns/PowerBiEmbedColumnEdit";
import { fetchWorkspaces } from "../../../../store/admin/actions";
import { TextColumnEdit } from "./editColumns/TextColumnEdit";
import { AddWebpartDialog } from "./addWebpartDialog/AddWebpartDialog";
import { useState } from "react";

export const PortalPageEditMode = () => {
  const dispatch = useDispatch();
  const { pagesSlice } = useSelector((state: IApplicationState) => state);
  const [addWebpartDialogIsOpen, setAddWebpartDialogIsOpen] = useState(false);

  const currentPage = pagesSlice.currentPage;

  const determineInitialValues = () => {
    // Default values
    let initialFormValues: IPortalPageContent = {
      rows: currentPage.page?.content?.rows || [],
    };
    return initialFormValues;
  };

  const createValidationSchema = () => {
    let yupObject: any = {
      // selectedGroups: Yup.array().min(1, intl.formatMessage({ id: "general.required" })),
      // selectedResource: Yup.array().min(1, intl.formatMessage({ id: "general.required" })),
    };
    return Yup.object().shape(yupObject);
  };

  const _submitForm = (values: IPortalPageContent) => {
    // Guard
    if (currentPage.page == null) {
      return null;
    }

    dispatch(
      updatePortalPageContent(
        currentPage.page.id,
        values.rows.length === 0 ? null : values
      )
    );
  };

  React.useEffect(() => {
    if (currentPage.page !== null) {
      dispatch(fetchWorkspaces());
    }
  }, [currentPage.page]);

  if (currentPage.page == null) {
    return null;
  }

  return (
    <Formik
      initialValues={determineInitialValues()}
      onSubmit={(valuesToSubmit) => {
        _submitForm(valuesToSubmit);
      }}
      validationSchema={() => createValidationSchema()}
    >
      {(formikProps) => {
        const { handleSubmit, values, setFieldValue } = formikProps;

        const addWebpart = (type: PortalPageColumnType) => {
          // Close the WebpartSelectorDialog
          setAddWebpartDialogIsOpen(false);

          // Create a new instance of the existing values
          const newValues = {
            rows: [...values.rows],
          };
          // Add a new row with a column to the object.
          let objectToAdd: any = {
            type,
            width: 100,
          };

          // Add the default values if the webpart is a powerBi webpart
          if (type === PortalPageColumnType.PowerBi) {
            objectToAdd.resourceType = PortalPagePowerBiType.Report;
          }
          if (type === PortalPageColumnType.Text) {
          }

          newValues.rows.push({
            columns: [objectToAdd],
          });

          // Update the formik values object.
          setFieldValue("rows", newValues.rows);
        };

        return (
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <FormikPortalPageController {...formikProps} />

            {values.rows.map((row, rowIndex) => {
              // Since we only support one column for now, pick the first item.
              if (row.columns.length === 0) return null;

              const column = row.columns[0];
              let columnUi: null | JSX.Element = null;
              if (column.type === PortalPageColumnType.PowerBi) {
                columnUi = (
                  <PowerBiEmbedColumnEdit
                    key={rowIndex}
                    formikProps={formikProps}
                    column={column as IPortalPageColumnPowerBi}
                    rowIndex={rowIndex}
                  />
                );
              }
              if (column.type === PortalPageColumnType.Text) {
                columnUi = (
                  <TextColumnEdit
                    key={rowIndex}
                    formikProps={formikProps}
                    column={column as IPortalPageColumnText}
                    rowIndex={rowIndex}
                  />
                );
              }

              return (
                <div className="portal-page-row" key={rowIndex + "row"}>
                  {columnUi}
                </div>
              );
            })}

            <div className="portal-page-row">
              <div
                className="portal-page-column"
                onClick={() => setAddWebpartDialogIsOpen(true)}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <DefaultButton iconProps={{ iconName: "Add" }}>
                  <FormattedMessage id="portalPage.addWebpart.add" />
                </DefaultButton>
              </div>
              <AddWebpartDialog
                isOpen={addWebpartDialogIsOpen}
                onDismiss={() => setAddWebpartDialogIsOpen(false)}
                onSelect={(type) => addWebpart(type)}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
