import * as React from "react";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IButtonProps,
  IconButton,
  IStackTokens,
  ITag,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  TagPicker,
  TeachingBubble,
  TextField,
} from "@fluentui/react";
import {
  filterSuggestedTags,
  IOneNavigationPage,
  IPortalPageCreate,
  IPortalPageUpdate,
  listContainsTagList,
  SlicePieceStatus,
} from "@one/core";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { fetchSecurityGroups } from "../../../../store/admin/actions";
import { Group } from "@microsoft/microsoft-graph-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { PortalPageType } from "@one/core/build/enums/portal/PortalPageType";
import { PortalPageStatus } from "@one/core/build/enums/portal/PortalPageStatus";
import {
  createPortalPage,
  updatePortalPage,
} from "../../../../store/pages/actions";
import { PagesActionTypes } from "../../../../store/pages/types";
import { IconPicker } from "@one/web";

export interface IAddPageModalProps {
  pageToUpdate?: IOneNavigationPage | null;
  setPageToUpdate?: (parent: IOneNavigationPage | null) => void;
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  parentPage: IOneNavigationPage | undefined;
  setParentPage: (parent: IOneNavigationPage | undefined) => void;
}

export const UpsertPageModal = (_props: IAddPageModalProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { pagesSlice, adminSlice } = useSelector(
    (state: IApplicationState) => state
  );

  let [teachingBubbleVisible, setTeachingBubbleVisible] = useState(false);
  const [securityGroups, setSecurityGroups] = useState<ITag[]>([]);
  const verticalGapStackTokens: IStackTokens = {
    childrenGap: 10,
  };

  const dialogContentProps = {
    type: DialogType.largeHeader,
    title: intl.formatMessage({
      id: "addModalPage.dialog.title",
      defaultMessage: "Pagina aanmaken",
    }),
    subText: intl.formatMessage({
      id: "addModalPage.dialog.subtext",
      defaultMessage:
        "Maak een nieuwe pagina aan om een nieuw rapportoverzicht te tonen.",
    }),
  };

  const hideDialog = () => {
    _props.setIsOpen(false);
    _props.setParentPage(undefined);

    if (_props.setPageToUpdate) {
      _props.setPageToUpdate(null);
    }
  };

  useEffect(() => {
    dispatch(fetchSecurityGroups());
  }, []);

  useEffect(() => {
    let mappedGroups = adminSlice.securityGroups.data.map((g: Group) => {
      return {
        key: g.id,
        name: g.displayName,
      } as ITag;
    });
    setSecurityGroups(mappedGroups);
  }, [adminSlice.securityGroups]);

  const createValidationSchema = () => {
    let yupObject: any = {
      name: Yup.string().required(
        intl.formatMessage({
          id: "general.required",
          defaultMessage: "Dit veld is verplicht.",
        })
      ),
    };
    return Yup.object().shape(yupObject);
  };

  const _submitForm = async (formValues) => {
    if (_props.pageToUpdate) {
      let portalPage: IPortalPageUpdate = {
        id: _props.pageToUpdate.id,
        displayName: formValues.name,
        icon: formValues.icon,
        type: PortalPageType.Public,
        status: PortalPageStatus.Draft,
        securityGroups: (formValues.securityGroups as ITag[]).map((s) =>
          s.key.toString()
        ),
      };
      dispatch(updatePortalPage(portalPage));
    } else {
      let portalPage: IPortalPageCreate = {
        displayName: formValues.name,
        parentPageId: _props.parentPage ? _props.parentPage.id : null,
        icon: formValues.icon,
        type: PortalPageType.Public,
        status: PortalPageStatus.Draft,
        securityGroups: (formValues.securityGroups as ITag[]).map((s) =>
          s.key.toString()
        ),
      };
      dispatch(createPortalPage(portalPage));
    }
  };

  const teachingBubbleButtonProps: IButtonProps = {
    text: intl.formatMessage({
      id: "general.ok",
      defaultMessage: "OK",
    }),
    onClick: () => setTeachingBubbleVisible(false),
  };

  useEffect(() => {
    if (!_props.isOpen) {
      dispatch({ type: PagesActionTypes.RESET_PAGES_STATUSSES });
    }
  }, [_props.isOpen]);

  useEffect(() => {
    if (
      pagesSlice.pages.updateStatus === SlicePieceStatus.Success ||
      pagesSlice.pages.createStatus === SlicePieceStatus.Success
    ) {
      _props.setIsOpen(false);
      _props.setParentPage(undefined);

      if (_props.setPageToUpdate) {
        _props.setPageToUpdate(null);
      }
    }
  }, [pagesSlice.pages.updateStatus, pagesSlice.pages.createStatus]);

  let initialFormValues = {
    name: "",
    icon: "",
    securityGroups: [] as ITag[],
  };

  if (_props.pageToUpdate) {
    initialFormValues = {
      name: _props.pageToUpdate.name,
      icon: _props.pageToUpdate.icon ? _props.pageToUpdate.icon : "",
      securityGroups: _props.pageToUpdate.securityGroups.map((x) => {
        let foundSecurityGroup = securityGroups.filter((s) => s.key === x)[0];
        return {
          key: foundSecurityGroup.key,
          name: foundSecurityGroup.name,
        } as ITag;
      }),
    };
  }

  return (
    <Dialog
      hidden={!_props.isOpen}
      dialogContentProps={dialogContentProps}
      onDismiss={() => hideDialog()}
    >
      {pagesSlice.pages.createStatus === SlicePieceStatus.Error && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          dismissButtonAriaLabel="Close"
        >
          <FormattedMessage id="oneNavigation.action.create.error" />
        </MessageBar>
      )}

      {(pagesSlice.pages.updateStatus === SlicePieceStatus.Error ||
        pagesSlice.pages.createStatus === SlicePieceStatus.Error) && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          dismissButtonAriaLabel="Close"
          styles={{ root: { marginBottom: "10px" } }}
        >
          <FormattedMessage id="oneNavigation.action.update.error" />
        </MessageBar>
      )}

      <Formik
        initialValues={initialFormValues}
        onSubmit={(valuesToSubmit, { setSubmitting }) => {
          setTimeout(() => {
            _submitForm(valuesToSubmit);
            setSubmitting(false);
          }, 500);
        }}
        validationSchema={() => createValidationSchema()}
      >
        {(formikProps) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          } = formikProps;

          let dialogFooter = (
            <DialogFooter>
              <PrimaryButton
                text={intl.formatMessage({
                  id: "general.save",
                  defaultMessage: "Opslaan",
                })}
                type="submit"
                disabled={
                  pagesSlice.pages.updateStatus ===
                    SlicePieceStatus.IsFetching ||
                  pagesSlice.pages.createStatus === SlicePieceStatus.IsFetching
                }
              />
              <DefaultButton
                onClick={() => hideDialog()}
                text={intl.formatMessage({
                  id: "general.cancel",
                  defaultMessage: "Annuleren",
                })}
              />
            </DialogFooter>
          );

          return (
            <form onSubmit={handleSubmit}>
              <Stack tokens={verticalGapStackTokens}>
                <div>
                  <TextField
                    label={intl.formatMessage({
                      id: "addModalPage.dialog.input.pageName",
                      defaultMessage: "Pagina naam",
                    })}
                    id="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={
                      errors.name && touched.name ? errors.name : undefined
                    }
                    className={errors.name && touched.name ? "error" : ""}
                  />
                </div>
                <div>
                  <IconPicker
                    label={intl.formatMessage({
                      id: "addModalPage.dialog.input.icon",
                      defaultMessage: "FluentUi Icon",
                    })}
                    errorMessage={
                      errors.icon && touched.icon ? errors.icon : undefined
                    }
                    selectedIcon={values.icon}
                    onSave={(iconName) => setFieldValue("icon", iconName)}
                  />
                </div>
                <div>
                  <Stack horizontal verticalAlign="center">
                    <Label styles={{ root: { lineHeight: "normal" } }}>
                      <FormattedMessage id="addModalPage.dialog.input.securityGroups" />
                    </Label>
                    <IconButton
                      iconProps={{ iconName: "Info" }}
                      title="Info"
                      ariaLabel="Info"
                      id="targetButton"
                      styles={{ root: { marginBottom: -3 } }}
                      onClick={() => setTeachingBubbleVisible(true)}
                    />
                  </Stack>
                  {teachingBubbleVisible && (
                    <TeachingBubble
                      target="#targetButton"
                      primaryButtonProps={teachingBubbleButtonProps}
                      onDismiss={() => setTeachingBubbleVisible(false)}
                      headline={intl.formatMessage({
                        id: "addModalPage.dialog.teachingBubble.headline",
                        defaultMessage: "Toegang tot de pagina",
                      })}
                    >
                      <FormattedMessage
                        id="addModalPage.dialog.teachingBubble.text"
                        defaultMessage="Hiermee kun je bepalen wie de pagina mag zien en benaderen. Waneer je geen groepen aanvinkt heeft iedereen toegang."
                      ></FormattedMessage>
                    </TeachingBubble>
                  )}

                  <TagPicker
                    selectedItems={
                      values.securityGroups !== undefined
                        ? values.securityGroups
                        : []
                    }
                    disabled={
                      adminSlice.workspaces.fetchStatus ===
                      SlicePieceStatus.IsFetching
                    }
                    onChange={(items) => {
                      setFieldValue("securityGroups", items);
                    }}
                    onBlur={() => setFieldTouched("securityGroups")}
                    styles={{
                      text: {
                        // Change the border-color to the same used on a textfield with an error.
                        borderColor:
                          touched.securityGroups && errors.securityGroups
                            ? "rgb(164, 38, 44)"
                            : undefined,
                      },
                    }}
                    onResolveSuggestions={(filter, items) =>
                      filterSuggestedTags(
                        securityGroups,
                        filter,
                        items as ITag[]
                      )
                    }
                    onEmptyResolveSuggestions={(selectedItems) =>
                      securityGroups.filter(
                        (tag) => !listContainsTagList(tag, selectedItems)
                      )
                    }
                    pickerSuggestionsProps={{
                      noResultsFoundText: intl.formatMessage({
                        id: "general.tagpicker.noTagsFound",
                      }),
                    }}
                  />
                </div>
              </Stack>

              {dialogFooter}
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
