import { ITag } from "@fluentui/react";
import { SlicePieceStatus } from "@one/core";
import { AdminActionTypes, AdminState } from "./types";

const initialState: AdminState = {
	securityGroups: {
		data: [],
		fetchStatus: SlicePieceStatus.None,
	},
	workspaces: {
		data: [],
		fetchStatus: SlicePieceStatus.None,
		tags: [],
	},
	reportsForWorkspace: {
		data: [],
		fetchStatus: SlicePieceStatus.None,
		tags: [],
	},
	dashboardsForWorkspace: {
		data: [],
		fetchStatus: SlicePieceStatus.None,
		tags: [],
	},
};

export function adminReducer(state = initialState, action: { type: AdminActionTypes; payload: any }): AdminState {
	switch (action.type) {
		case AdminActionTypes.FETCH_SECURITY_GROUPS_STARTED:
			return {
				...state,
				securityGroups: {
					...state.securityGroups,
					fetchStatus: SlicePieceStatus.IsFetching,
				},
			};
		case AdminActionTypes.FETCH_SECURITY_GROUPS_SUCCESS:
			return {
				...state,
				securityGroups: {
					...state.securityGroups,
					data: action.payload,
					fetchStatus: SlicePieceStatus.Success,
				},
			};
		case AdminActionTypes.FETCH_SECURITY_GROUPS_FAILURE:
			return {
				...state,
				securityGroups: {
					...state.securityGroups,
					fetchStatus: SlicePieceStatus.Error,
				},
			};

		// Workspaces
		case AdminActionTypes.FETCH_WORKSPACES_STARTED:
			return {
				...state,
				workspaces: {
					...state.workspaces,
					fetchStatus: SlicePieceStatus.IsFetching,
				},
			};
		case AdminActionTypes.FETCH_WORKSPACES_SUCCESS:
			return {
				...state,
				workspaces: {
					...state.workspaces,
					data: action.payload,
					fetchStatus: SlicePieceStatus.Success,
					tags: action.payload.map((w) => {
						return {
							key: w.id,
							name: w.name,
						} as ITag;
					}),
				},
			};
		case AdminActionTypes.FETCH_WORKSPACES_FAILURE:
			return {
				...state,
				workspaces: {
					...state.workspaces,
					fetchStatus: SlicePieceStatus.Error,
				},
			};

		// Reports
		case AdminActionTypes.FETCH_REPORTS_FOR_WORKSPACE_STARTED:
			return {
				...state,
				reportsForWorkspace: {
					...state.reportsForWorkspace,
					fetchStatus: SlicePieceStatus.IsFetching,
				},
			};
		case AdminActionTypes.FETCH_REPORTS_FOR_WORKSPACE_SUCCESS:
			return {
				...state,
				reportsForWorkspace: {
					...state.reportsForWorkspace,
					data: action.payload,
					fetchStatus: SlicePieceStatus.Success,
					tags: action.payload.map((r) => {
						return {
							key: r.id,
							name: r.name,
						} as ITag;
					}),
				},
			};
		case AdminActionTypes.FETCH_REPORTS_FOR_WORKSPACE_FAILURE:
			return {
				...state,
				reportsForWorkspace: {
					...state.reportsForWorkspace,
					fetchStatus: SlicePieceStatus.Error,
				},
			};

		// Dashboards
		case AdminActionTypes.FETCH_DASHBOARDS_FOR_WORKSPACE_STARTED:
			return {
				...state,
				dashboardsForWorkspace: {
					...state.dashboardsForWorkspace,
					fetchStatus: SlicePieceStatus.IsFetching,
				},
			};
		case AdminActionTypes.FETCH_DASHBOARDS_FOR_WORKSPACE_SUCCESS:
			return {
				...state,
				dashboardsForWorkspace: {
					...state.dashboardsForWorkspace,
					data: action.payload,
					fetchStatus: SlicePieceStatus.Success,
					tags: action.payload.map((d) => {
						return {
							key: d.id,
							name: d.displayName,
						} as ITag;
					}),
				},
			};
		case AdminActionTypes.FETCH_DASHBOARDS_FOR_WORKSPACE_FAILURE:
			return {
				...state,
				dashboardsForWorkspace: {
					...state.dashboardsForWorkspace,
					fetchStatus: SlicePieceStatus.Error,
				},
			};

		default:
			return state;
	}
}
