import { ISetting } from "@one/core";
import { ISupportedLanguage } from "../../models/interfaces/ISupportedLanguage";

export const decideLanguageToUse = (settings: {
	applicationSettings: ISetting[];
	tenantSettings: ISetting[];
	userSettings: ISetting[];
}): ISupportedLanguage => {
	let languageToReturn: ISupportedLanguage = "nl";

	if (settings !== undefined) {
		// first check tenant language
		if (settings.tenantSettings !== undefined && settings.tenantSettings.length > 0) {
			for (let se of settings.tenantSettings) {
				if (se.key === "Language") {
					languageToReturn = se.value as ISupportedLanguage;
					break;
				}
			}
		}

		// check if there is an application language
		if (settings.applicationSettings !== undefined && settings.applicationSettings.length > 0) {
			for (let se of settings.applicationSettings) {
				if (se.key === "Language") {
					languageToReturn = se.value as ISupportedLanguage;
					break;
				}
			}
		}

		// check if there is a user language
		if (settings.userSettings !== undefined && settings.userSettings.length > 0) {
			for (let se of settings.userSettings) {
				if (se.key === "Language") {
					languageToReturn = se.value as ISupportedLanguage;
					break;
				}
			}
		}
	}

	return languageToReturn;
};
