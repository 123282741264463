// Framework & third-party
import React, { useEffect } from "react";
import Loader from "react-loader-spinner";
import { initializeIcons } from "@fluentui/react";
import { delay, SlicePieceStatus } from "@one/core";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, IntlProvider } from "react-intl";

// Assets
import { translationSets } from "./assets/translations";

// Stores & actions
import { fetchCurrentGraphUser, fetchCurrentUser } from "./store/user/actions";
import {
  checkTenantSubscription,
  fetchSettings,
  setIsOnMobile,
} from "./store/application/actions";

// Models & interfaces
import { IApplicationState } from "./models/interfaces/IApplicationState";
import { TenantSubscriptionStatus } from "./models/enums/TenantSubscriptionStatus";

// Components & functions
import { decideLanguageToUse } from "./common/helperFunctions/LanguageHelpers";
import { ApplicationLayout } from "./components/ApplicationLayout";
import { OneWebTranslationSets } from "@one/web";
import { OneThemeProvider } from "./styles/OneThemeProvider";

initializeIcons();

export const App = () => {
  const dispatch = useDispatch();
  const { applicationContextSlice, userSlice } = useSelector(
    (state: IApplicationState) => state
  );
  const { tenantSubscription } = applicationContextSlice;
  const localeSetting = decideLanguageToUse(
    applicationContextSlice.settings.data
  );

  // ---===[ UseEffects ]===---
  useEffect(() => {
    // Fetch data from API
    dispatch(checkTenantSubscription());
    dispatch(fetchCurrentUser());
    dispatch(fetchCurrentGraphUser());
    dispatch(fetchSettings());

    // Dispatch the setIsOnMobile event.
    dispatch(setIsOnMobile(window.innerWidth <= 768));

    // Add an event listener to the 'resize' event to determine whether the app is run on mobile or not.
    window.addEventListener("resize", () => {
      delay(() => {
        dispatch(setIsOnMobile(window.innerWidth <= 768));
      }, 500);
    });
  }, []); // On init

  // ---===[ Functions ]===---
  const renderAppContent = (): JSX.Element => {
    if (
      tenantSubscription.fetchStatus === SlicePieceStatus.IsFetching ||
      applicationContextSlice.settings.fetchStatus ===
        SlicePieceStatus.IsFetching ||
      userSlice.currentUser.fetchStatus === SlicePieceStatus.IsFetching ||
      userSlice.currentGraphUser.fetchStatus === SlicePieceStatus.IsFetching
    ) {
      return (
        <div className="app-centered">
          <div className="loaderWrapper">
            <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
          </div>
        </div>
      );
    }

    if (tenantSubscription.data === TenantSubscriptionStatus.Active) {
      return <ApplicationLayout />;
    }

    return (
      <div className="app-centered">
        <h1 style={{ textAlign: "center" }}>
          {tenantSubscription.data === TenantSubscriptionStatus.Inactive && (
            <FormattedMessage
              id="subscription.inactive"
              defaultMessage="Uw abonnement is verlopen."
            />
          )}
          {tenantSubscription.data === TenantSubscriptionStatus.None && (
            <FormattedMessage
              id="subscription.none"
              defaultMessage="U heeft geen abonnement voor One."
            />
          )}
          {tenantSubscription.data === TenantSubscriptionStatus.Error && (
            <FormattedMessage
              id="subscription.inactive"
              defaultMessage="Uw abonnement is verlopen."
            />
          )}
        </h1>
      </div>
    );
  };

  // ---===[ Rendering ]===---
  return (
    <OneThemeProvider theme={applicationContextSlice.theme}>
      <IntlProvider
        locale={localeSetting}
        messages={{
          ...OneWebTranslationSets[localeSetting],
          ...translationSets[localeSetting],
        }}
      >
        {renderAppContent()}
      </IntlProvider>
    </OneThemeProvider>
  );
};
