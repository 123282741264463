import { Card, CardContent } from "@one/web";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import { hasAccess, PermissionCodes } from "@one/core";

export const Welcome = () => {
	const { userSlice } = useSelector((state: IApplicationState) => state);
	return (
		<Card>
			<CardContent>
				<h3>
					<FormattedMessage id="general.welcome" /> {userSlice.currentUser.data.givenName}
				</h3>
				<p>
					{hasAccess(userSlice.currentUser.data, [PermissionCodes.ManageReportingPortalPages]) ? (
						<FormattedMessage id="welcome.noPagesAdmin" />
					) : (
						<FormattedMessage id="welcome.noPagesUser" />
					)}
				</p>
			</CardContent>
		</Card>
	);
};
