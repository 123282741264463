import { IPortalPage, ISlicePiece, SlicePieceStatus } from "@one/core";

export enum PagesActionTypes {
	FETCH_PAGES_STARTED = "FETCH_PAGES_STARTED",
	FETCH_PAGES_SUCCESS = "FETCH_PAGES_SUCCESS",
	FETCH_PAGES_FAILURE = "FETCH_PAGES_FAILURE",

	CREATE_PAGE_STARTED = "CREATE_PAGE_STARTED",
	CREATE_PAGE_SUCCESS = "CREATE_PAGE_SUCCESS",
	CREATE_PAGE_FAILURE = "CREATE_PAGE_FAILURE",

	UPDATE_PAGE_STARTED = "UPDATE_PAGE_STARTED",
	UPDATE_PAGE_SUCCESS = "UPDATE_PAGE_SUCCESS",
	UPDATE_PAGE_FAILURE = "UPDATE_PAGE_FAILURE",

	UPDATE_PAGE_STATUS_STARTED = "UPDATE_PAGE_STATUS_STARTED",
	UPDATE_PAGE_STATUS_SUCCESS = "UPDATE_PAGE_STATUS_SUCCESS",
	UPDATE_PAGE_STATUS_FAILURE = "UPDATE_PAGE_STATUS_FAILURE",

	UPDATE_PAGE_CONTENT_STARTED = "UPDATE_PAGE_CONTENT_STARTED",
	UPDATE_PAGE_CONTENT_SUCCESS = "UPDATE_PAGE_CONTENT_SUCCESS",
	UPDATE_PAGE_CONTENT_FAILURE = "UPDATE_PAGE_CONTENT_FAILURE",

	RESET_PAGES_STATUSSES = "RESET_PAGES_STATUSSES",

	SET_CURRENT_PAGE = "SET_CURRENT_PAGE",
	SET_EDIT_MODE = "SET_EDIT_MODE",
	SET_ACTION_TO_EXECUTE = "SET_ACTION_TO_EXECUTE",

	UPDATE_PAGE_ORDER_STARTED = "UPDATE_PAGE_ORDER_STARTED",
	UPDATE_PAGE_ORDER_SUCCESS = "UPDATE_PAGE_ORDER_SUCCESS",
	UPDATE_PAGE_ORDER_FAILURE = "UPDATE_PAGE_ORDER_FAILURE",
}

export type PagesState = Readonly<{
	pages: ISlicePiece<IPortalPage[]>;
	pagesFlatList: IPortalPage[];

	currentPage: {
		page: IPortalPage | null;
		isInEditMode: boolean;
		actionToExecute: any;
		updateContentStatus: SlicePieceStatus;
	};
}>;
